<div class="align-items-center d-flex flex-column justify-content-center" style="min-height: 100vh !important">
  <p class="mb-5 text-center">
    {{ 'common.sso-error.Zut !' | translate }}
    <br />
    <span *ngIf="error === 'no_user'">{{ "common.sso-error.Vous n'avez pas de compte de paramétré" | translate }}</span>
    <span *ngIf="error === 'fnacdarty_no_user'">
      Vous n'avez pas de compte de paramétré avec cette adresse email ({{ email }}), utilisez-vous bien votre adresse email nominative ?
    </span>
    <span *ngIf="error === 'user_deleted'">{{ 'common.sso-error.Votre compte est désactivé' | translate }} ({{ email }})</span>
    <span *ngIf="error === 'no_right'">{{ "common.sso-error.Votre compte n'a pas le droit d'accéder au backoffice" | translate }} ({{ email }})</span>
    <br />
    <span *ngIf="error !== undefined && error !== 'no_right' && error !== 'user_deleted'">
      {{ "common.sso-error.Notre équipe à été avertie et s'occupe déjà de vous ! Nous revenons vers vous via mail dans les plus brefs délais" | translate }}.
    </span>
    <br />
  </p>
  <a class="btn btn-primary" (click)="retry()" *ngIf="error !== 'user_deleted'">{{ 'common.sso-error.Retenter la connexion' | translate }}</a>
</div>
