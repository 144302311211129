<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <!--<div style="width: 23rem"></div>-->

    <!--logo-->
    <div class="row justify-content-center">
      <div class="col-auto">
        <img style="width: 75px" src="assets/logo.png" />
      </div>
    </div>

    <!--form-->
    <div class="row justify-content-center mt-3 mb-3">
      <div class="col-12 col-md-6">
        <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
          <div class="container-fluid">
            <div class="row mt-4">
              <div class="col-12 text-center justify-content-center">
                <div class="text-left">
                  <h3 *ngIf="!done">{{ 'common.mail-auth.Bienvenue dans votre espace hiji' | translate }}</h3>
                  <h3 *ngIf="done">{{ 'common.mail-auth.Email de connexion envoyé' | translate }}</h3>
                </div>
                <div class="text-left mt-2" *ngIf="!done">{{ 'common.mail-auth.Entrez votre email afin de recevoir un lien de connexion' | translate }}</div>
              </div>
            </div>

            <div class="row mt-4 justify-content-center" *ngIf="done">
              <div class="col-12 col-md-8 text-center">
                {{ 'common.mail-auth.Vous allez reçevoir un email contenant un lien personnel de connexion' | translate }}.
                <br />
                <br />
                {{ 'common.mail-auth.Vous pouvez fermer cette page' | translate }}.
              </div>
            </div>

            <div class="row mt-4 justify-content-center" *ngIf="!done">
              <div class="col-12 col-md-8">
                <div class="form-group" style="margin-bottom: 2rem">
                  <input class="form-control" type="email" [placeholder]="'common.login.E-mail' | translate" formControlName="email" />
                </div>
              </div>
            </div>

            <!--Errors handling-->
            <div class="row justify-content-center mt-4" *ngIf="!loading && !done && f.submitted && (!form.valid || error)">
              <div class="col-12 col-md-8">
                <ul class="errors">
                  <li *ngIf="form.hasError('required', 'email')">{{ "common.login.L'E-mail est requis" | translate }}</li>
                  <li *ngIf="form.hasError('email', 'email')">{{ "common.login.L'E-mail est invalide" | translate }}</li>
                  <li *ngIf="error">{{ error }}</li>
                </ul>
              </div>
            </div>

            <div class="row mt-3" *ngIf="!done">
              <div class="col text-center">
                <button class="btn btn-primary text-uppercase" type="submit" [disabled]="loading">
                  {{ 'common.mail-auth.Valider' | translate }}
                  <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</hiji-wavy-container>
