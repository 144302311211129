// ENTITY fr.hiji.metier.certif.dto.front.modality.ModalityLiteDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityType } from './ModalityType';

export class ModalityLiteDTO {

  idModality: number | null = 0;
  type: ModalityType | null = null;
  name: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<ModalityLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.ModalityLiteDTO'] = ModalityLiteDTO;

reverseMapping[ModalityLiteDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.ModalityLiteDTO';

fields['fr.hiji.metier.certif.dto.front.modality.ModalityLiteDTO']  = {
    idModality: 'java.lang.Integer',
    type: 'fr.hiji.metier.certif.entities.modality.ModalityType',
    name: 'com.ic2.sc.MultilingualString'
};
