<div class="container-fluid bg-light-30p h-100 py-3" style="overflow-y: hidden">
  <a class="text-body" routerLink="/">
    <div class="bg-white border rounded-3 animate__animated animate__bounceInUp">
      <div class="row g-1">
        <div class="col-auto d-flex align-items-center">
          <i class="fa-light fa-person-digging fa-3x text-warning p-4"></i>
        </div>
        <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
          <div class="row">
            <div class="col-auto">
              <b>In development</b>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <small>Go back to main menu</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
