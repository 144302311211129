<div *ngIf="loadingRepositoryName">
  <i class="fa-light fa-spinner spinning"></i>
</div>
<ng-container *ngIf="!loadingRepositoryName">
  <ic2-textassist
    name="repository"
    placeholder="{{ placeholder }}"
    [(ngModel)]="idRepository"
    (ngModelChange)="setValue($event)"
    [keyExtractor]="keyExtractorRepository"
    [values]="repositories"
    [presenter]="presenterRepository"
    (textChanges)="repositoryTextChanged($event)"
    [initialText]="repositoryStartName ? (repositoryStartName | ms) : ''"
    [disabled]="loading || disabled"
    [min]="1"
    [showClearButton]="!loading"
    #ta
  >
    <ng-template let-val>
      <span>{{ presenterRepository(val) }}</span>
    </ng-template>
    <ng-container append>
      <div class="input-group-text" *ngIf="loading">
        <i class="fa-light fa-spinner spinning"></i>
      </div>
    </ng-container>
  </ic2-textassist>
</ng-container>
