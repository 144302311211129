import { Injectable } from '@angular/core';
import { L, MsPipe } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Tag } from '../ic2/entities/Tag';
import { TagService } from '../ic2/services/TagService';

@Injectable({
  providedIn: 'root',
})
export class TagManager {
  private tagslist: Tag[] = [];
  loaded: boolean = false;
  loading: boolean = false;
  observableGestureFlatTagsList: [] = [];
  tagsReloaded: Subject<Tag[]> = new Subject<Tag[]>();

  constructor(
    private tagService: TagService,
    private msPipe: MsPipe,
    private translate: TranslateService
  ) {}

  load(): Promise<void> {
    this.loading = true;
    return new Promise<void>((resolve, reject) => {
      this.tagService.getTags().subscribe(
        (data) => {
          this.loaded = true;
          this.loading = false;
          this.tagslist = data.sort((a, b) => a.order - b.order);
          resolve();
          this.tagsReloaded.next(this.tagslist);
        },
        (err) => {
          L.e(err);
          reject();
        }
      );
    });
  }

  reload() {
    this.load().then();
  }

  getSonsOf(idParent: number, withDel: boolean): Tag[] {
    return this.getTags(withDel)
      .filter((n) => n.idParent === idParent)
      .sort((a, b) => a.order - b.order);
  }

  getAllParentsOf(idTag: number, withDel: boolean): Tag[] {
    let tag = this.tagslist.find((t) => t.idTag === idTag);
    if (tag === undefined && this.loaded) {
      const t = new Tag();
      t.name.obj[this.translate.currentLang] = this.translate.instant('common.tag-manager.Tag non chargé, veuillez actualiser la page');
      return [t];
    } else if (!this.loaded) {
      const t = new Tag();
      t.name.obj[this.translate.currentLang] = this.translate.instant('common.tag-manager.Tag en cours de chargement');
      return [t];
    }
    if (tag.idParent !== 0) {
      let t = this.getAllParentsOf(tag.idParent, withDel);
      t.unshift(tag);
      return t;
    }
    return [tag];
  }

  public getTags(withDel: boolean) {
    let tags: Tag[] = this.tagslist;
    if (!withDel) tags = this.tagslist.filter((t) => !t.del);

    return tags;
  }

  public getTag(idTag: number, withDel: boolean = true) {
    let tags: Tag[] = this.tagslist;
    if (!withDel) tags = this.tagslist.filter((t) => !t.del);
    const tag = tags.find((t) => t.idTag === idTag);
    if (tag === undefined && this.loaded) {
      const t = new Tag();
      t.name.obj[this.translate.currentLang] = this.translate.instant('common.tag-manager.Tag non chargé, veuillez actualiser la page');
      return t;
    } else if (!this.loaded) {
      const t = new Tag();
      t.name.obj[this.translate.currentLang] = this.translate.instant('common.tag-manager.Tag en cours de chargement');
      return t;
    }
    return tag;
  }

  public searchTags(withDel: boolean, research: string = null) {
    let tags: Tag[] = this.getTags(withDel);
    if (research === null || research === '') {
      return tags;
    } else {
      tags = this.filterList(tags, (t) =>
        this.msPipe
          .transform(t.name)
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            research
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      );
    }
    // let tags: Tag[] = this.getTags(withDel);
    return tags;
  }
  hasParentDeleted(idParent: number) {
    let tag: Tag = this.tagslist.find((t) => t.idTag === idParent);
    if (tag) {
      if (tag.del) {
        return true;
      }
      return this.hasParentDeleted(tag.idParent);
    }
    return false;
  }

  filterList(listeComplete: Tag[], predicat: (el: Tag) => boolean): Tag[] {
    const res = listeComplete.filter(predicat);
    const resInitial = [...res];

    //On supprime les résultats de la liste
    listeComplete = listeComplete.filter((o) => !res.some((r) => r.idTag == o.idTag));

    let parents = resInitial;
    while (parents.length > 0) {
      const nouveauxParents = listeComplete.filter((t) => parents.some((r) => r.idParent == t.idTag));
      //B+A
      if (nouveauxParents.length > 0) {
        res.push(...nouveauxParents);
        listeComplete = listeComplete.filter((t) => !nouveauxParents.some((r) => r.idTag == t.idTag));
      }
      parents = nouveauxParents;
    }

    let enfants = resInitial;
    while (enfants.length > 0) {
      const nouveauxEnfants = listeComplete.filter((t) => enfants.some((r) => r.idTag == t.idParent));
      //B+A
      if (nouveauxEnfants.length > 0) {
        res.push(...nouveauxEnfants);
        listeComplete = listeComplete.filter((t) => !nouveauxEnfants.some((r) => r.idTag == t.idTag));
      }
      enfants = nouveauxEnfants;
    }

    return res; //ABCDG
  }
}
