import { NPS_VALUES } from '../dto/NpsValues';
import { Criteria } from '../ic2/entities/Criteria';
import { CriteriaType } from '../ic2/entities/CriteriaType';

export function getValuesForCriteria(criteria: Criteria) {
  let values = [];
  if (criteria.type === CriteriaType.ECHELLE_4) values = [criteria.value1, criteria.value2, criteria.value3, criteria.value4];
  else if (criteria.type === CriteriaType.ECHELLE_5) values = [criteria.value1, criteria.value2, criteria.value3, criteria.value4, criteria.value5];
  else if (criteria.type === CriteriaType.BINAIRE) values = [criteria.value1, criteria.value5];
  else if (criteria.type === CriteriaType.NPS || criteria.type === CriteriaType.ECHELLE_10) values = NPS_VALUES;
  else throw new Error('Critère non géré ' + criteria.type.lib);
  return values;
}
