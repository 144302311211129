// fr.hiji.metier.conf.services.back.submodules.TMSModuleCfgBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class TMSModuleCfgBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateModuleTMSEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TMSModuleCfgBOService.updateModuleTMSEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
