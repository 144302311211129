// ENTITY fr.hiji.metier.mif.entities.quiz.FormationQuizOpenEndedAnswer generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationQuizOpenEndedAnswer {

  idFormationQuizAnswer: number | null = 0;
  idQuestion: number | null = 0;
  answer: string | null = '';
  date: Date | null = null;

  constructor(init?:Partial<FormationQuizOpenEndedAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.quiz.FormationQuizOpenEndedAnswer'] = FormationQuizOpenEndedAnswer;

reverseMapping[FormationQuizOpenEndedAnswer.name] = 'fr.hiji.metier.mif.entities.quiz.FormationQuizOpenEndedAnswer';

fields['fr.hiji.metier.mif.entities.quiz.FormationQuizOpenEndedAnswer']  = {
    idFormationQuizAnswer: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    answer: 'java.lang.String',
    date: 'java.time.Instant'
};
