<div class="d-flex h-100 align-items-center" *ngIf="criteriatype === CriteriaType.BINAIRE">
  <div
    class="text-center p-1 rounded-start d-flex justify-content-center align-items-center"
    [ngStyle]="{ flexGrow: positive, backgroundColor: colors.vertFonce }"
  >
    <div title="{{ positive * 100 }}" *ngIf="positive != 0">{{ positive * 100 | number : '1.0-0' }}%</div>
    <div *ngIf="positive == 0">&nbsp;</div>
  </div>
  <div
    class="text-center p-1 rounded-end d-flex justify-content-center align-items-center"
    [ngStyle]="{ flexGrow: verynegative, backgroundColor: colors.rouge }"
  >
    <div title="{{ verynegative * 100 }}" *ngIf="verynegative != 0">{{ verynegative * 100 | number : '1.0-0' }}%</div>
    <div *ngIf="verynegative == 0">&nbsp;</div>
  </div>
</div>

<div class="d-flex h-100 align-items-center" *ngIf="criteriatype === CriteriaType.ECHELLE_5 || criteriatype === CriteriaType.ECHELLE_4">
  <div
    class="text-center p-1 rounded-start d-flex justify-content-center align-items-center"
    [ngStyle]="{ flexGrow: positive, backgroundColor: colors.vertFonce }"
  >
    <div title="{{ positive * 100 }}" *ngIf="positive != 0">{{ positive * 100 | number : '1.0-0' }}%</div>
    <div *ngIf="positive == 0">&nbsp;</div>
  </div>
  <div
    class="text-center p-1 d-flex justify-content-center align-items-center"
    [ngStyle]="{ flexGrow: neutral, backgroundColor: criteriatype === CriteriaType.ECHELLE_5 ? colors.gris : colors.vertclair }"
  >
    <div title="{{ neutral * 100 }}" *ngIf="neutral != 0">{{ neutral * 100 | number : '1.0-0' }}%</div>
    <div *ngIf="neutral == 0">&nbsp;</div>
  </div>
  <div class="text-center p-1 d-flex justify-content-center align-items-center" [ngStyle]="{ flexGrow: negative, backgroundColor: colors.orange }">
    <div title="{{ negative * 100 }}" *ngIf="negative != 0">{{ negative * 100 | number : '1.0-0' }}%</div>
    <div *ngIf="negative == 0">&nbsp;</div>
  </div>
  <div
    class="text-center p-1 rounded-end d-flex justify-content-center align-items-center"
    [ngStyle]="{ flexGrow: verynegative, backgroundColor: colors.rouge }"
  >
    <div title="{{ verynegative * 100 }}" *ngIf="verynegative != 0">{{ verynegative * 100 | number : '1.0-0' }}%</div>
    <div *ngIf="verynegative == 0">&nbsp;</div>
  </div>
</div>

<div
  class="d-flex h-100 align-items-center"
  *ngIf="criteriatype !== CriteriaType.ECHELLE_5 && criteriatype !== CriteriaType.ECHELLE_4 && criteriatype !== CriteriaType.BINAIRE"
>
  {{ criteriatype?.lib }} non géré
</div>
