// ENTITY fr.hiji.metier.mif.entities.quiz.FormationQuizAnswer generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationQuizAnswer {

  idFormationQuizAnswer: number | null = 0;
  idFormationSession: number | null = 0;
  idFormationQuiz: number | null = 0;
  idQuiz: number | null = 0;
  idUserAnswering: number | null = 0;
  startAnsweringDate: Date | null = null;
  creationDate: Date | null = null;
  score: number | null = 0;
  total: number | null = 0;
  draft: boolean | null = false;

  constructor(init?:Partial<FormationQuizAnswer>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.quiz.FormationQuizAnswer'] = FormationQuizAnswer;

reverseMapping[FormationQuizAnswer.name] = 'fr.hiji.metier.mif.entities.quiz.FormationQuizAnswer';

fields['fr.hiji.metier.mif.entities.quiz.FormationQuizAnswer']  = {
    idFormationQuizAnswer: 'java.lang.Integer',
    idFormationSession: 'java.lang.Integer',
    idFormationQuiz: 'java.lang.Integer',
    idQuiz: 'java.lang.Integer',
    idUserAnswering: 'java.lang.Integer',
    startAnsweringDate: 'java.time.Instant',
    creationDate: 'java.time.Instant',
    score: 'java.lang.Double',
    total: 'java.lang.Double',
    draft: 'java.lang.Boolean'
};
