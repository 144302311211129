// fr.hiji.metier.fbs.services.FeedbackCategoryBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FeedbackCategory } from '../entities/FeedbackCategory';

@Injectable({
  providedIn: 'root',
})
export class FeedbackCategoryBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFeedbackCategories(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FeedbackCategory>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackCategoryBOService.getFeedbackCategories';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFeedBackGategory(idFC: number): RpcRequestBuilder<FeedbackCategory> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackCategoryBOService.getFeedBackGategory';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFC, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.fbs.entities.FeedbackCategory');
  }

  saveFeedBackCategory(fc: FeedbackCategory): RpcRequestBuilder<FeedbackCategory> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackCategoryBOService.saveFeedBackCategory';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fc, 'fr.hiji.metier.fbs.entities.FeedbackCategory', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.fbs.entities.FeedbackCategory');
  }

}
