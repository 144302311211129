// ENTITY fr.hiji.metier.user.dto.login.SSOConfigDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SSOConfigDTO {

  name: string | null = '';
  id: string | null = '';
  defaultConfig: boolean | null = false;
  issuer: string | null = '';
  clientId: string | null = '';
  scope: string | null = '';
  responseType: string | null = '';
  strictDiscoveryDocumentValidation: boolean | null = false;
  loginUrl: string | null = '';
  frontTokenEndpoint: string | null = '';
  hideOnLoginPage: boolean | null = false;

  constructor(init?:Partial<SSOConfigDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.login.SSOConfigDTO'] = SSOConfigDTO;

reverseMapping[SSOConfigDTO.name] = 'fr.hiji.metier.user.dto.login.SSOConfigDTO';

fields['fr.hiji.metier.user.dto.login.SSOConfigDTO']  = {
    name: 'java.lang.String',
    id: 'java.lang.String',
    defaultConfig: 'java.lang.Boolean',
    issuer: 'java.lang.String',
    clientId: 'java.lang.String',
    scope: 'java.lang.String',
    responseType: 'java.lang.String',
    strictDiscoveryDocumentValidation: 'java.lang.Boolean',
    loginUrl: 'java.lang.String',
    frontTokenEndpoint: 'java.lang.String',
    hideOnLoginPage: 'java.lang.Boolean'
};
