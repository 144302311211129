// ENTITY fr.hiji.metier.campaign.dto.front.ExchangeManagerSelectionDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ExchangeRepositorySelectionDTO } from './ExchangeRepositorySelectionDTO';

export class ExchangeManagerSelectionDTO {

  idUserManager: number | null = 0;
  lastName: string | null = '';
  firstName: string | null = '';
  repositories: ExchangeRepositorySelectionDTO[] | null = [];

  constructor(init?:Partial<ExchangeManagerSelectionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.ExchangeManagerSelectionDTO'] = ExchangeManagerSelectionDTO;

reverseMapping[ExchangeManagerSelectionDTO.name] = 'fr.hiji.metier.campaign.dto.front.ExchangeManagerSelectionDTO';

fields['fr.hiji.metier.campaign.dto.front.ExchangeManagerSelectionDTO']  = {
    idUserManager: 'java.lang.Integer',
    lastName: 'java.lang.String',
    firstName: 'java.lang.String',
    repositories: 'java.util.List<fr.hiji.metier.campaign.dto.front.ExchangeRepositorySelectionDTO>'
};
