<div class="row mt-3 justify-content-center" *ngIf="!loading">
  <div class="col-auto col-sm-2"></div>
  <div class="col-auto">
    <ngb-pagination
      [collectionSize]="collectionSize"
      [(page)]="page"
      [pageSize]="pageSize"
      [maxSize]="maxSize"
      [rotate]="rotate"
      (pageChange)="onPageChange($event)"
    ></ngb-pagination>
  </div>
  <div class="col-auto col-sm-2 text-start">
    <div class="row flex-nowrap">
      <label class="col-auto g-0 col-form-label my-fs-2 me-1" for="nbPerPage">{{ 'common.smart-pagination.Montrer' | translate }} :</label>
      <div class="col-auto g-0 text-nowrap">
        <select class="form-control form-control-sm h-100" name="nbPerPage" [(ngModel)]="pageSize" (change)="onPageSizeChange($event)">
          <option [value]="n" *ngFor="let n of nbPossiblePerPage">{{ n }} {{ 'common.smart-pagination.lignes' | translate }}</option>
        </select>
      </div>
    </div>
  </div>
</div>
