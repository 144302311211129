import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModuleConfig } from '@ngx-translate/core';

export class InterpolatedMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams) {
    return params.translateService.parser.interpolate(params.key, params.interpolateParams);
  }
}

//Créé pour combler le problème d'override automatique dans le forChild() : https://github.com/ngx-translate/core/issues/1278
export const TRANSLATE_CHILD_CONFIG: TranslateModuleConfig = {
  missingTranslationHandler: { provide: MissingTranslationHandler, useClass: InterpolatedMissingTranslationHandler },
};
