// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleSurveyDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleSurveyDTO {

  moduleEnquete: boolean | null = false;
  changeBONamesToSurveyMode: boolean | null = false;

  constructor(init?:Partial<ModuleSurveyDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleSurveyDTO'] = ModuleSurveyDTO;

reverseMapping[ModuleSurveyDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleSurveyDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleSurveyDTO']  = {
    moduleEnquete: 'java.lang.Boolean',
    changeBONamesToSurveyMode: 'java.lang.Boolean'
};
