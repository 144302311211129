// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionParticipant generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationSessionParticipant {

  idFormationSession: number | null = 0;
  idUser: number | null = 0;
  idRegistration: number | null = 0;
  hideFromHome: boolean | null = false;
  managerExpectationDefined: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<FormationSessionParticipant>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionParticipant'] = FormationSessionParticipant;

reverseMapping[FormationSessionParticipant.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionParticipant';

fields['fr.hiji.metier.mif.entities.session.FormationSessionParticipant']  = {
    idFormationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    idRegistration: 'java.lang.Integer',
    hideFromHome: 'java.lang.Boolean',
    managerExpectationDefined: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
