// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventAnimationBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventAnimation } from './FormationEventAnimation';
import { FormationEventAnimationTrainerBODTO } from './FormationEventAnimationTrainerBODTO';
import { FormationEventAnimationOG } from './FormationEventAnimationOG';

export class FormationEventAnimationBODTO {

  formationEventAnimation: FormationEventAnimation | null = null;
  trainers: FormationEventAnimationTrainerBODTO[] | null = [];
  ogs: FormationEventAnimationOG[] | null = [];

  constructor(init?:Partial<FormationEventAnimationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventAnimationBODTO'] = FormationEventAnimationBODTO;

reverseMapping[FormationEventAnimationBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventAnimationBODTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventAnimationBODTO']  = {
    formationEventAnimation: 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimation',
    trainers: 'java.util.List<fr.hiji.metier.mif.dto.back.event.FormationEventAnimationTrainerBODTO>',
    ogs: 'java.util.List<fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationOG>'
};
