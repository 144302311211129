<div class="modal-header">
  <h5 class="modal-title">
    {{ 'common.quiz-qcm-details-modal.Détail des réponses' | translate }}
  </h5>
  <button class="btn-close" type="button" data-bs-dismiss="modal" (click)="modal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    @if (data.length > 0) {
      <div class="text-center">
        <h4 class="my-2">{{ data[0].question | ms }}</h4>
        @if (!data[0].questionImage.isEmpty()) {
          <img style="height: auto; width: 150px" [src]="data[0].questionImage | ms" />
        }
      </div>
    }
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ 'common.quiz-qcm-details-modal.Réponse' | translate }}</th>
          <th scope="col">{{ 'common.quiz-qcm-details-modal.Taux de réponse' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        @for (data of data; track $index) {
          <tr>
            <td>
              @if (!data.answerImage.isEmpty()) {
                <img style="height: auto; width: 80px" [src]="data.answerImage | ms" alt="Image de la réponse" />
              }
              @if (data.answer) {
                {{ data.answer | ms }}
                @if (data.isGoodAnswer) {
                  <i class="fa-light fa-check text-success ms-1" ngbTooltip="{{ 'common.quiz-qcm-details-modal.Bonne réponse' | translate }}"></i>
                } @else if (!data.isGoodAnswer) {
                  <i class="fa-light fa-xmark text-danger ms-1" ngbTooltip="{{ 'common.quiz-qcm-details-modal.Mauvaise réponse' | translate }}"></i>
                }
              }
            </td>
            <td>
              @if (data.nbQuestionAnswered != 0) {
                {{ (data.distribution / data.nbQuestionAnswered) * 100 | number: '1.0-0' }}% ({{ data.distribution }}/{{ data.nbQuestionAnswered }})
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button class="btn btn-dark" type="button" data-dismiss="modal" (click)="modal.dismiss()">{{ 'common.global.Fermer' | translate }}</button>
</div>
