// ENTITY fr.hiji.metier.campaign.dto.front.HistogramDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class HistogramDTO {

  idExchange: number | null = 0;
  creationDate: Date | null = null;
  avgObservation: number | null = 0;
  countObservation: number | null = 0;
  avgAutoObservation: number | null = 0;
  countAutoObservation: number | null = 0;

  constructor(init?:Partial<HistogramDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.HistogramDTO'] = HistogramDTO;

reverseMapping[HistogramDTO.name] = 'fr.hiji.metier.campaign.dto.front.HistogramDTO';

fields['fr.hiji.metier.campaign.dto.front.HistogramDTO']  = {
    idExchange: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    avgObservation: 'java.lang.Double',
    countObservation: 'java.lang.Integer',
    avgAutoObservation: 'java.lang.Double',
    countAutoObservation: 'java.lang.Integer'
};
