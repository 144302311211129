// ENTITY fr.hiji.metier.ema.entities.template.EMATemplateEnabledStep generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAStep } from './EMAStep';

export class EMATemplateEnabledStep {

  idEMATemplate: number | null = 0;
  step: EMAStep | null = null;

  constructor(init?:Partial<EMATemplateEnabledStep>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.template.EMATemplateEnabledStep'] = EMATemplateEnabledStep;

reverseMapping[EMATemplateEnabledStep.name] = 'fr.hiji.metier.ema.entities.template.EMATemplateEnabledStep';

fields['fr.hiji.metier.ema.entities.template.EMATemplateEnabledStep']  = {
    idEMATemplate: 'java.lang.Integer',
    step: 'fr.hiji.metier.ema.entities.EMAStep'
};
