import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { L, TextassistComponent } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from '../../ic2/entities/UserFuncAvatarDTO';
import { UserSearchService } from '../../ic2/services/UserSearchService';

@Component({
  selector: 'hiji-user-textassist',
  templateUrl: './user-textassist.component.html',
  styleUrls: ['./user-textassist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserTextassistComponent),
      multi: true,
    },
  ],
})
export class UserTextassistComponent implements OnChanges, ControlValueAccessor {
  @Input()
  initialText: string = null;
  @Input()
  placeholder: string = '';
  @Input()
  clearAfterAdd: boolean = false;
  @Input()
  withExternal: boolean = true;
  @Input()
  withAddButton: boolean = true;
  @Input()
  backofficeMode: boolean = true;
  @Input()
  useUserPerimeter: boolean = true;
  @Input()
  predicat = (user: UserFuncAvatarDTO): boolean => {
    return true;
  };

  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Output()
  objectChange: EventEmitter<UserFuncAvatarDTO> = new EventEmitter<UserFuncAvatarDTO>();

  clearing: boolean = false;

  @ViewChild('ta') ta: TextassistComponent<UserFuncAvatarDTO>;

  idUser: number;
  user: UserFuncAvatarDTO;
  users: UserFuncAvatarDTO[] = [];
  onChange: any = () => {};
  onTouch: any = () => {};

  userPresenter = (e: UserFuncAvatarDTO) => e.firstName + ' ' + e.lastName + ' (' + e.email + ')';

  constructor(private userSearchService: UserSearchService, private cdRef: ChangeDetectorRef) {}

  registerOnChange(fn: number) {
    this.onChange = fn;
  }
  registerOnTouched(fn: number) {
    this.onTouch = fn;
  }

  writeValue(idUser: number) {
    this.idUser = idUser;
  }

  ngOnChanges(changes: SimpleChanges) {}

  add(user) {
    this.setValue(user);
  }

  setValue(user: UserFuncAvatarDTO) {
    if (this.clearing) return;
    if (user !== null && user !== undefined) {
      this.idUser = user.idUser;
    } else {
      this.idUser = null;
    }
    this.change();
    if (this.clearAfterAdd) {
      this.clearing = true;
      this.ta.clear();
      this.users = [];
      this.clearing = false;
    }
  }

  change() {
    if (this.user === null && this.clearAfterAdd) return;
    this.objectChange.emit(this.user);
    this.onChange(this.idUser);
    this.onTouch(this.idUser);
  }

  userTextChanged(text: string): void {
    //utiliser usersearchservice pour avoir de funcUserdto
    if (text.length === 0) return;
    if (this.backofficeMode) {
      if (this.useUserPerimeter)
        this.userSearchService.searchUserBackWithPerimeter(text, this.withExternal).subscribe(
          (data) => {
            this.users = data.filter(this.predicat);
          },
          (err) => {
            L.e(err);
          }
        );
      else
        this.userSearchService.searchUserBack(text, this.withExternal).subscribe(
          (data) => {
            this.users = data.filter(this.predicat);
          },
          (err) => {
            L.e(err);
          }
        );
    } else
      this.userSearchService.searchUser(text, this.withExternal).subscribe(
        (data) => {
          this.users = data.filter(this.predicat);
        },
        (err) => {
          L.e(err);
        }
      );
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
