// ENTITY fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserFormation generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CampaignPDIUserFormation {

  idCampaign: number | null = 0;
  idUser: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  url: string | null = '';
  comment: string | null = '';

  constructor(init?:Partial<CampaignPDIUserFormation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserFormation'] = CampaignPDIUserFormation;

reverseMapping[CampaignPDIUserFormation.name] = 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserFormation';

fields['fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserFormation']  = {
    idCampaign: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    url: 'java.lang.String',
    comment: 'java.lang.String'
};
