import { Component, Input } from '@angular/core';

@Component({
  selector: 'hiji-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrl: './stat-card.component.scss',
})
export class StatCardComponent {
  @Input({ required: true }) title: string;
  @Input() value: string;
  @Input({ required: true }) icon: string;
  @Input() detail: string;
  @Input() footer: string;
}
