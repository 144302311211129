<ng-container *ngFor="let tag of getParents()">
  <span [ngStyle]="{ whiteSpace: noWrap ? 'nowrap' : null }">
    <span class="my-fs-2 mx-2" *ngIf="getParents()[0] !== tag">&gt;</span>
    <span class="my-fs-2" [ngStyle]="{ color: tag.colorCode }" title="{{ tag.name | ms }}">{{ tag.name | ms }}</span>
    <i
      class="fa-light fa-info-circle ms-2 pointer"
      (click)="descInfoClick.emit(tag)"
      *ngIf="frontMode && !hideDescInfoButton && (tag.description | ms) !== ''"
    ></i>
    <span
      class="ms-2"
      ngbTooltip="{{ 'common.tag-display.Afficher dans les radar ou comme rubriques de l\'EA' | translate }}"
      container="body"
      *ngIf="!frontMode && tag.showInRadar"
    >
      <i class="fa-light fa-star fa-sm text-primary"></i>
    </span>
    <span
      class="ms-2"
      ngbTooltip="{{ 'common.tag-display.Cacher dans le détail des compétences des gestes observables du front' | translate }}"
      container="body"
      *ngIf="!frontMode && tag.hideInFO"
    >
      <i class="fa-light fa-eye-slash"></i>
    </span>
    <i
      class="fa-light fa-exclamation-triangle text-warning my-fs-2 ms-2"
      ngbTooltip="{{ 'common.tag-display.Parent supprimé' | translate }}"
      container="body"
      *ngIf="!frontMode && !tag.del && tagManager.hasParentDeleted(tag.idParent)"
    ></i>
    <i
      class="fa-light fa-exclamation-triangle text-danger my-fs-2 ms-2"
      ngbTooltip="{{ 'common.tag-display.Cette compétence est supprimée' | translate }}"
      container="body"
      *ngIf="tag.del"
    ></i>
  </span>
</ng-container>
