import { AfterContentInit, AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { L, MsPipe, RpcError, RpcErrorHandler, RpcRequestBuilder, deepCopy, string_to_slug } from '@ic2/ic2-lib';

import { TranslateService } from '@ngx-translate/core';
import { Subscription, filter } from 'rxjs';
import { ModalService } from '../components/modal/modal.service';
import { HijiRight } from '../ic2/entities/HijiRight';
import { AuthService } from '../services/auth.service';
import { Ic2ToastrService } from '../services/ic2-toastr.service';
import { DateUtils } from './DateUtils';

@Component({ template: '' })
export class AbstractViewAddEdit<T> implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  private sub: Subscription;
  loading: boolean = true;
  deleteLoading: boolean = false;
  editLoading: boolean = false;
  addLoading: boolean = false;
  edit: boolean = false;
  add: boolean = false;
  view: boolean = false;
  addOrDelBtnClicked: boolean = false;
  forceReloadOnSave: boolean = false;
  viewType: string;
  id: number = null;
  _data: T = null;
  get data(): T {
    return this._data;
  }
  set data(data: T) {
    this._data = data;
    this.backupData = deepCopy(this._data);
  }
  backupData: T;
  admin: boolean = false;
  gestionnaire: boolean = false;
  string_to_slug = string_to_slug;
  DateUtils: typeof DateUtils = DateUtils;
  _ngForm: NgForm = null;
  get ngForm() {
    return this._ngForm;
  }
  @ViewChild('ngForm', { static: false }) set ngForm(val: NgForm) {
    this._ngForm = val;
    if (this._ngForm !== undefined) {
      this.form = val.form;
      this.formDefined();
    }
  }
  form: FormGroup = null;

  modalService: ModalService;
  route: ActivatedRoute;
  router: Router;
  authService: AuthService;
  msPipe: MsPipe;
  vae_base_translate: TranslateService;
  ic2ToastrService: Ic2ToastrService;

  static tempDuplicateData: any = null;

  loadMethod: (id: number) => RpcRequestBuilder<T> = null;
  saveMethod: (dto: T) => RpcRequestBuilder<T> = null;

  constructor(injector: Injector) {
    this.modalService = injector.get(ModalService);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.authService = injector.get(AuthService);
    this.msPipe = injector.get(MsPipe);
    this.vae_base_translate = injector.get(TranslateService);
    this.ic2ToastrService = injector.get(Ic2ToastrService);
    this.admin = this.authService.has(HijiRight.ADMIN);
    this.gestionnaire = this.authService.has(HijiRight.GESTIONNAIRE, HijiRight.ADMIN);
  }

  static getClosestRouteParam(route: ActivatedRoute, param: string): any {
    while (route) {
      if (route.snapshot.paramMap.has(param)) {
        return route.snapshot.paramMap.get(param);
      }
      route = route.parent;
    }
    return null;
  }

  ngOnInit(): void {
    this.addOrDelBtnClicked = false;
    this.sub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((_) => {
      this.routeChanged();
    });
  }

  formDefined() {
    //peut servir à définir des validateurs (async ou non) sur le form mais attention la validation du form se fait avant la modif des ngModel, donc il faut forcément utiliser le
    //form.value pour faire ses validations
    //Vaut mieux utiliser customFormValidators()
  }

  routeChanged() {
    this.addOrDelBtnClicked = false;
    if (this.ngForm) {
      (<any>this.ngForm).submitted = false;
      this.ngForm.form.markAsPristine();
    }

    this.viewType = AbstractViewAddEdit.getClosestRouteParam(this.route, 'view');
    if (this.viewType === 'add') {
      this.add = true;
      this.edit = false;
      this.view = false;
      this.data = this.initData();
      this.loading = false;
    } else {
      if (!['view', 'edit'].includes(this.viewType) || AbstractViewAddEdit.getClosestRouteParam(this.route, 'id') === undefined) {
        this.router.navigate(['/']);
        return;
      }

      this.add = false;
      if (this.viewType === 'edit') {
        this.edit = true;
        this.view = false;
      } else {
        this.edit = false;
        this.view = true;
      }

      if (this.id !== parseInt(AbstractViewAddEdit.getClosestRouteParam(this.route, 'id'), 10)) {
        this.id = parseInt(AbstractViewAddEdit.getClosestRouteParam(this.route, 'id'), 10);
        this.loadData(this.id);
      } else {
        if (this.forceReloadOnSave) {
          this.loading = true;
          this.loadData(this.id);
        } else this.data = deepCopy(this.backupData);
      }
    }
    if (AbstractViewAddEdit.tempDuplicateData !== null) {
      this.data = AbstractViewAddEdit.tempDuplicateData;
      AbstractViewAddEdit.tempDuplicateData = null;
    }
    this.modeChange();
  }

  modeChange() {}

  ngAfterViewInit() {}
  ngAfterContentInit() {
    this.routeChanged();
  }

  customFormValidators(): (() => object | null)[] {
    return [];
  }

  private runFormValidation(): ValidationErrors | null {
    let errors = {};
    for (const validator of this.customFormValidators()) {
      const res = validator();
      if (res !== null) Object.assign(errors, res);
    }
    return Object.keys(errors).length === 0 ? null : errors;
  }

  valid(): boolean {
    //Run custom sync validation
    this.form.setErrors(this.runFormValidation());
    if (!this.ngForm.form.valid) {
      console.log(this.ngForm);
      return false;
    }
    return true;
  }

  showErrors() {
    if (!this.ngForm) return false; //fix for when UI is refreshing
    return this.ngForm.submitted && !this.form.valid && !this.form.disabled;
  }

  ngOnDestroy() {
    if (!this.sub) throw new Error('sub is null, maybe you forgot to call super.ngOnInit() in your ngOnInit() ?');
    this.sub.unsubscribe();
  }

  protected initData(): T {
    throw new Error('not implemented');
  }

  protected loadData(id: number) {
    if (this.loadMethod === null) throw new Error('not implemented');
    this.loading = true;
    this.loadMethod(id)
      .defaultOnError()
      .execute((data) => {
        this.data = data;
        this.loading = false;
        this.dataLoaded();
      });
  }

  protected dataLoaded() {}

  navigateBackToView() {
    //TODO automatiser ça ? il suffirait potentiellement de trouver le "add" / "edit" dans la route et le remplacer par "view"
    throw new Error('not implemented');
  }

  beforeDeleteClicked(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.modalService.openModal(
        'confirm',
        this.vae_base_translate.instant(
          "common.vae.modal.delete.Cet élément restera visible ou il est utilisé dans le backoffice \n Il ne sera plus possible d'assigner cet élément par la suite \n Êtes-vous sûrs de vouloir le supprimer ?"
        ),
        () => {
          this.onDeleteConfirmed().then((ok) => {
            resolve(ok);
          });
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  onDeleteConfirmed(): Promise<boolean> {
    throw new Error('not implemented');
  }

  deleteClicked() {
    this.addOrDelBtnClicked = true;
    this.deleteLoading = true;
    this.beforeDeleteClicked().then((ok) => {
      if (ok)
        this.useSaveMethod((data) => {
          this.data = data;
          this.deleteLoading = false;
          this.navigateBackToView();
        }, this.deleteErrorReceived.bind(this));
      else this.deleteLoading = false;
    });
  }

  /**
   * Created to allow overriding the subscribe to subscribeWithProgress when we need file upload
   */
  useSaveMethod(onSuccess: (value: T) => void, error: RpcErrorHandler) {
    this.saveMethod(this.data).onError(error).execute(onSuccess);
  }

  deleteErrorReceived(error: RpcError) {
    L.e(error);
    this.deleteLoading = false;
    this.globalErrorReceived(error);
  }

  beforeSaveEditClicked(): Promise<boolean> {
    return Promise.resolve(true);
  }

  //TODO rename this to "editClicked"
  saveEditClicked() {
    this.addOrDelBtnClicked = true;
    if (!this.valid()) {
      return;
    }

    if (this.editLoading) return;
    this.editLoading = true;
    this.beforeSaveEditClicked().then((ok) => {
      if (ok)
        this.useSaveMethod((data) => {
          this.data = data;
          this.editLoading = false;
          this.navigateBackToView();
        }, this.saveEditErrorReceived.bind(this));
      else this.editLoading = false;
    });
  }

  saveEditErrorReceived(error: RpcError) {
    L.e(error);
    this.editLoading = false;
    this.globalErrorReceived(error);
  }

  beforeAddClicked(): Promise<boolean> {
    return Promise.resolve(true);
  }

  addClicked() {
    this.addOrDelBtnClicked = true;
    if (!this.valid()) {
      return;
    }

    if (this.addLoading) return;
    this.addLoading = true;
    this.beforeAddClicked().then((ok) => {
      if (ok)
        this.useSaveMethod((data) => {
          this.data = data;
          this.addLoading = false;
          this.navigateBackToView();
        }, this.addErrorReceived.bind(this));
      else this.addLoading = false;
    });
  }

  addErrorReceived(error: RpcError) {
    L.e(error);
    this.addLoading = false;
    this.globalErrorReceived(error);
  }

  globalErrorReceived(error: RpcError) {}

  hasError(error: string, startWith: string, formGroup = this.form) {
    for (const [name, control] of Object.entries(formGroup.controls)) {
      if (control instanceof FormGroup && this.hasError(error, startWith, control)) return true;
      if (!name.startsWith(startWith)) continue;
      if (control.hasError(error)) return true;
    }
    return false;
  }
}
