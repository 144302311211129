// ENTITY fr.hiji.metier.fbs.dto.back.FeedbackStatBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FeedbackCategoryStatBODTO } from './FeedbackCategoryStatBODTO';
import { FeedbackPerMonthBODTO } from './FeedbackPerMonthBODTO';
import { FeedbackPerAreaBODTO } from './FeedbackPerAreaBODTO';

export class FeedbackStatBODTO {

  nbTotal: number | null = 0;
  feedbackCategoryStat: FeedbackCategoryStatBODTO[] | null = [];
  feedbackPerArea: FeedbackPerAreaBODTO[] | null = [];
  FeedbackPerMonth: FeedbackPerMonthBODTO[] | null = [];
  nbSentToGroup: number | null = 0;
  nbSentToUniquePerson: number | null = 0;
  nbSentBecauseSolicitation: number | null = 0;
  nbSolicitation: number | null = 0;

  constructor(init?:Partial<FeedbackStatBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.dto.back.FeedbackStatBODTO'] = FeedbackStatBODTO;

reverseMapping[FeedbackStatBODTO.name] = 'fr.hiji.metier.fbs.dto.back.FeedbackStatBODTO';

fields['fr.hiji.metier.fbs.dto.back.FeedbackStatBODTO']  = {
    nbTotal: 'java.lang.Integer',
    feedbackCategoryStat: 'java.util.List<fr.hiji.metier.fbs.dto.back.FeedbackCategoryStatBODTO>',
    feedbackPerArea: 'java.util.List<fr.hiji.metier.fbs.dto.back.FeedbackPerAreaBODTO>',
    FeedbackPerMonth: 'java.util.List<fr.hiji.metier.fbs.dto.back.FeedbackPerMonthBODTO>',
    nbSentToGroup: 'java.lang.Integer',
    nbSentToUniquePerson: 'java.lang.Integer',
    nbSentBecauseSolicitation: 'java.lang.Integer',
    nbSolicitation: 'java.lang.Integer'
};
