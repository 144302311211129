// ENTITY fr.hiji.metier.dashboard.dto.ParticipationStatsBySurveyBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { SurveyStatsParticipationPerDayBODTO } from './SurveyStatsParticipationPerDayBODTO';

export class ParticipationStatsBySurveyBODTO {

  name: MultilingualString | null = new MultilingualString();
  idCampaign: number | null = 0;
  nbParticipant: number | null = 0;
  participationPerDay: SurveyStatsParticipationPerDayBODTO[] | null = [];

  constructor(init?:Partial<ParticipationStatsBySurveyBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.dashboard.dto.ParticipationStatsBySurveyBODTO'] = ParticipationStatsBySurveyBODTO;

reverseMapping[ParticipationStatsBySurveyBODTO.name] = 'fr.hiji.metier.dashboard.dto.ParticipationStatsBySurveyBODTO';

fields['fr.hiji.metier.dashboard.dto.ParticipationStatsBySurveyBODTO']  = {
    name: 'com.ic2.sc.MultilingualString',
    idCampaign: 'java.lang.Integer',
    nbParticipant: 'java.lang.Integer',
    participationPerDay: 'java.util.List<fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsParticipationPerDayBODTO>'
};
