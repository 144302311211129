// ENTITY fr.hiji.metier.referential.dto.back.QuizBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { QuizQuestionBODTO } from './QuizQuestionBODTO';
import { Quiz } from './Quiz';

export class QuizBODTO {

  quiz: Quiz | null = null;
  questions: QuizQuestionBODTO[] | null = [];
  userCreator: string | null = '';
  userUpdater: string | null = '';

  constructor(init?:Partial<QuizBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.back.QuizBODTO'] = QuizBODTO;

reverseMapping[QuizBODTO.name] = 'fr.hiji.metier.referential.dto.back.QuizBODTO';

fields['fr.hiji.metier.referential.dto.back.QuizBODTO']  = {
    quiz: 'fr.hiji.metier.referential.entities.quiz.Quiz',
    questions: 'java.util.List<fr.hiji.metier.referential.dto.back.QuizQuestionBODTO>',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String'
};
