// ENTITY fr.hiji.metier.reporting.dto.back.ReportEMAConfigTemplateBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ReportEMAConfigTemplate } from './ReportEMAConfigTemplate';

export class ReportEMAConfigTemplateBODTO {

  template: ReportEMAConfigTemplate | null = null;
  name: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;

  constructor(init?:Partial<ReportEMAConfigTemplateBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.ReportEMAConfigTemplateBODTO'] = ReportEMAConfigTemplateBODTO;

reverseMapping[ReportEMAConfigTemplateBODTO.name] = 'fr.hiji.metier.reporting.dto.back.ReportEMAConfigTemplateBODTO';

fields['fr.hiji.metier.reporting.dto.back.ReportEMAConfigTemplateBODTO']  = {
    template: 'fr.hiji.metier.reporting.entities.ema.ReportEMAConfigTemplate',
    name: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean'
};
