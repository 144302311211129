// ENTITY fr.hiji.metier.certif.dto.front.modality.roleplay.result.ModalityRoleplayResultDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { CertificationExam } from './CertificationExam';
import { ModalityRoleplayAnswerSkillAvg } from './ModalityRoleplayAnswerSkillAvg';
import { ModalityRoleplayExam } from './ModalityRoleplayExam';
import { ModalityDTO } from './ModalityDTO';
import { ModalityRoleplayAnswerDTO } from './ModalityRoleplayAnswerDTO';
import { ModalityRoleplayTarget } from './ModalityRoleplayTarget';
import { Criteria } from './Criteria';
import { ObservableGestureDTO } from './ObservableGestureDTO';
import { ModalityRoleplayAnswerOG } from './ModalityRoleplayAnswerOG';

export class ModalityRoleplayResultDTO {

  modality: ModalityDTO | null = null;
  idRepository: number | null = 0;
  user: UserFuncAvatarDTO | null = null;
  repositoryName: MultilingualString | null = new MultilingualString();
  criterias: Criteria[] | null = [];
  roleplayAnswer: ModalityRoleplayAnswerDTO[] | null = [];
  observableGestures: ObservableGestureDTO[] | null = [];
  roleplayAnswerOg: ModalityRoleplayAnswerOG[] | null = [];
  roleplayExam: ModalityRoleplayExam | null = null;
  certificationExam: CertificationExam | null = null;
  averageScoreToSucceed: number | null = 0;
  roleplaySkillAvg: ModalityRoleplayAnswerSkillAvg[] | null = [];
  roleplaySkillTarget: ModalityRoleplayTarget[] | null = [];

  constructor(init?:Partial<ModalityRoleplayResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.roleplay.result.ModalityRoleplayResultDTO'] = ModalityRoleplayResultDTO;

reverseMapping[ModalityRoleplayResultDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.roleplay.result.ModalityRoleplayResultDTO';

fields['fr.hiji.metier.certif.dto.front.modality.roleplay.result.ModalityRoleplayResultDTO']  = {
    modality: 'fr.hiji.metier.certif.dto.front.modality.ModalityDTO',
    idRepository: 'java.lang.Integer',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    repositoryName: 'com.ic2.sc.MultilingualString',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    roleplayAnswer: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayAnswerDTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservableGestureDTO>',
    roleplayAnswerOg: 'java.util.List<fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerOG>',
    roleplayExam: 'fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayExam',
    certificationExam: 'fr.hiji.metier.certif.entities.session.CertificationExam',
    averageScoreToSucceed: 'java.lang.Double',
    roleplaySkillAvg: 'java.util.List<fr.hiji.metier.certif.entities.modality.roleplay.answer.ModalityRoleplayAnswerSkillAvg>',
    roleplaySkillTarget: 'java.util.List<fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplayTarget>'
};
