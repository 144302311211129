<ic2-textassist
  class="flex-fill text-body"
  [initialText]="initialText"
  [(ngModel)]="user"
  (ngModelChange)="setValue($event)"
  [values]="users"
  (textChanges)="userTextChanged($event)"
  [presenter]="userPresenter"
  [placeholder]="placeholder"
  [alwaysShowEverything]="true"
  [min]="1"
  [disabled]="disabled"
  #ta
>
  <ng-template let-data>
    <div class="py-2" style="line-height: 1">
      {{ data.firstName }} {{ data.lastName }}
      <span class="my-fs-1 text-muted">({{ data.email }})</span>
    </div>
  </ng-template>
  <ng-container class="input-group-append" append *ngIf="withAddButton">
    <button class="btn btn-secondary" type="button" (click)="add(user)"><i class="fa-light fa-plus"></i></button>
  </ng-container>
</ic2-textassist>
