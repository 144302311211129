<i class="fa-light fa-store" ngbTooltip="{{ 'common.organisation-icon.Enseigne' | translate }}" container="body" *ngIf="type === OrganisationType.ENSEIGNE"></i>
<i
  class="fa-light fa-people-arrows"
  ngbTooltip="{{ 'common.organisation-icon.Société' | translate }}"
  container="body"
  *ngIf="type === OrganisationType.SOCIETE"
></i>
<i
  class="fa-light fa-map-location-dot"
  ngbTooltip="{{ 'common.organisation-icon.Pays' | translate }}"
  container="body"
  *ngIf="type === OrganisationType.PAYS"
></i>
<i class="fa-light fa-globe" ngbTooltip="{{ 'common.organisation-icon.Région' | translate }}" container="body" *ngIf="type === OrganisationType.REGION"></i>
<i
  class="fa-light fa-building"
  ngbTooltip="{{ 'common.organisation-icon.Établissement' | translate }}"
  container="body"
  *ngIf="type === OrganisationType.ETABLISSEMENT"
></i>
<i class="fa-light fa-tag" ngbTooltip="{{ 'common.organisation-icon.Direction' | translate }}" container="body" *ngIf="type === OrganisationType.DIRECTION"></i>
<i class="fa-light fa-sitemap" ngbTooltip="{{ 'common.organisation-icon.Service' | translate }}" container="body" *ngIf="type === OrganisationType.SERVICE"></i>
<i
  class="fa-light fa-file-circle-question"
  ngbTooltip="{{ 'common.organisation-icon.Autre' | translate }}"
  container="body"
  *ngIf="type === OrganisationType.AUTRE"
></i>
<i class="fa-light fa-question-circle" ngbTooltip="{{ 'common.organisation-icon.Non défini' | translate }}" container="body" *ngIf="type === null"></i>
