// ENTITY fr.hiji.metier.conf.dto.submodules.Module180DTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Module180DTO {

  moduleCampaign: boolean | null = false;
  coacheeAllowedToExchange: boolean | null = false;
  actionPlanSharedBetweenManagers: boolean | null = false;
  campainFeaturePopulation: number | null = 0;
  collabAutoObsNeedExchangeMailEnabled: boolean | null = false;

  constructor(init?:Partial<Module180DTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.Module180DTO'] = Module180DTO;

reverseMapping[Module180DTO.name] = 'fr.hiji.metier.conf.dto.submodules.Module180DTO';

fields['fr.hiji.metier.conf.dto.submodules.Module180DTO']  = {
    moduleCampaign: 'java.lang.Boolean',
    coacheeAllowedToExchange: 'java.lang.Boolean',
    actionPlanSharedBetweenManagers: 'java.lang.Boolean',
    campainFeaturePopulation: 'java.lang.Integer',
    collabAutoObsNeedExchangeMailEnabled: 'java.lang.Boolean'
};
