<div class="modal-header">
  <h5 class="modal-title">{{ 'common.data-processing-modal.Traitement des données' | translate }}</h5>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row py-4 justify-content-center" *ngIf="loading">
      <div class="col-auto">
        <i class="fa-light fa-spinner spinning"></i>
      </div>
    </div>
    <div [innerHTML]="html"></div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-dark" type="button" data-dismiss="modal" (click)="modal.dismiss()">{{ 'common.data-processing-modal.Fermer' | translate }}</button>
</div>
