// fr.hiji.metier.certif.services.back.ModalityBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { ModalityLiteBODTO } from '../entities/ModalityLiteBODTO';
import { ModalityBODTO } from '../entities/ModalityBODTO';

@Injectable({
  providedIn: 'root',
})
export class ModalityBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getModality(id: number): RpcRequestBuilder<ModalityBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityBOService.getModality';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.modality.ModalityBODTO');
  }

  getModalities(filter: GenericFilter): RpcRequestBuilder<ListWithCount<ModalityLiteBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityBOService.getModalities';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  modalityNameAlreadyExists(name: MultilingualString, idModality: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityBOService.modalityNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  saveModality(dto: ModalityBODTO): RpcRequestBuilder<ModalityBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityBOService.saveModality';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.certif.dto.back.modality.ModalityBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.modality.ModalityBODTO');
  }

  uploadImage(): RpcRequestBuilder<string> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModalityBOService.uploadImage';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.String');
  }

}
