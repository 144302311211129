// fr.hiji.metier.certif.services.back.stats.CertificationStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount } from '@ic2/ic2-lib';
import { CertificationQuizOpenQuestionBODTO } from '../entities/CertificationQuizOpenQuestionBODTO';
import { RoleplayStatsBODTO } from '../entities/RoleplayStatsBODTO';
import { CertificationQuizQCMQuestionAnswerBODTO } from '../entities/CertificationQuizQCMQuestionAnswerBODTO';
import { CertificationStatsModalityBODTO } from '../entities/CertificationStatsModalityBODTO';
import { CertificationQuizQuestionQCMAnswerDistributionBODTO } from '../entities/CertificationQuizQuestionQCMAnswerDistributionBODTO';
import { CertificationStatsFiltersBODTO } from '../entities/CertificationStatsFiltersBODTO';
import { InterviewStatsBODTO } from '../entities/InterviewStatsBODTO';
import { ModalityType } from '../entities/ModalityType';
import { CertificationStatsInfoBODTO } from '../entities/CertificationStatsInfoBODTO';
import { CertificationQuizStatsBODTO } from '../entities/CertificationQuizStatsBODTO';

@Injectable({
  providedIn: 'root',
})
export class CertificationStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getInfos(filter: CertificationStatsFiltersBODTO): RpcRequestBuilder<CertificationStatsInfoBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsInfoBODTO');
  }

  getQuestionQCMAnswerDistribution(filter: CertificationStatsFiltersBODTO, idModality: number, idQuestion: number): RpcRequestBuilder<CertificationQuizQuestionQCMAnswerDistributionBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getQuestionQCMAnswerDistribution';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuestion, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getModalityInterview(idModality: number, filter: CertificationStatsFiltersBODTO): RpcRequestBuilder<InterviewStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getModalityInterview';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.stats.interview.InterviewStatsBODTO');
  }

  getVerbatims(filter: CertificationStatsFiltersBODTO, idModality: number, idObservableGesture: number, nb: number, page: number): RpcRequestBuilder<ListWithCount<String>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getVerbatims';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  downloadOGCSV(idModality: number, filter: CertificationStatsFiltersBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.downloadOGCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  downloadQuizVerbatimCSV(idModality: number, filter: CertificationStatsFiltersBODTO, idQuestion: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.downloadQuizVerbatimCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuestion, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getModality(filter: CertificationStatsFiltersBODTO, type: ModalityType): RpcRequestBuilder<CertificationStatsModalityBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getModality';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(type, 'fr.hiji.metier.certif.entities.modality.ModalityType', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityBODTO');
  }

  getModalityRoleplay(idModality: number, filter: CertificationStatsFiltersBODTO): RpcRequestBuilder<RoleplayStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getModalityRoleplay';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.stats.roleplay.RoleplayStatsBODTO');
  }

  getModalityQuiz(idModality: number, filter: CertificationStatsFiltersBODTO): RpcRequestBuilder<CertificationQuizStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getModalityQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizStatsBODTO');
  }

  getQuizzQCMQuestionAnswers(filter: CertificationStatsFiltersBODTO, idModality: number, idQuestion: number): RpcRequestBuilder<CertificationQuizQCMQuestionAnswerBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getQuizzQCMQuestionAnswers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuestion, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getQuizzOpenQuestionAnswers(filter: CertificationStatsFiltersBODTO, idModality: number, idQuestion: number, nb: number, page: number): RpcRequestBuilder<ListWithCount<CertificationQuizOpenQuestionBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.getQuizzOpenQuestionAnswers';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idQuestion, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(nb, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(page, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  downloadVerbatimCSV(idModality: number, filter: CertificationStatsFiltersBODTO, idObservableGesture: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationStatsBOService.downloadVerbatimCSV';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idModality, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(filter, 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
