// DB ENUM fr.hiji.metier.mif.entities.event.observation.FormationEventObservationType generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationEventObservationType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ACQUISITION_BEFORE = new FormationEventObservationType(1, "Acquisition avant", "ACQUISITION_BEFORE");
  static readonly ACQUISITION_AFTER = new FormationEventObservationType(2, "Acquisition après", "ACQUISITION_AFTER");
  static readonly SATISFACTION_PARTICIPANT = new FormationEventObservationType(3, "Satisfaction participant", "SATISFACTION_PARTICIPANT");

  static readonly values = [
    FormationEventObservationType.ACQUISITION_BEFORE,
 
    FormationEventObservationType.ACQUISITION_AFTER,
 
    FormationEventObservationType.SATISFACTION_PARTICIPANT
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationEventObservationType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationEventObservationType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.event.observation.FormationEventObservationType'] = FormationEventObservationType;
reverseMapping[FormationEventObservationType.name] = 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservationType';
