// fr.hiji.metier.dashboard.services.DashboardBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { DashboardBODTO } from '../entities/DashboardBODTO';

@Injectable({
  providedIn: 'root',
})
export class DashboardBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getStats(): RpcRequestBuilder<DashboardBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'DashboardBOService.getStats';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.dashboard.dto.DashboardBODTO');
  }

}
