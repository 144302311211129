// ENTITY fr.hiji.metier.ema.entities.EMA generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAStep } from './EMAStep';
import { EMAType } from './EMAType';

export class EMA {

  idEMA: number | null = 0;
  idUserObserved: number | null = 0;
  idUserManager: number | null = 0;
  creationDate: Date | null = null;
  step: EMAStep | null = null;
  startExchangeDate: Date | null = null;
  message: string | null = '';
  type: EMAType | null = null;
  supportPlan: string | null = '';
  doneWithACoach: boolean | null = false;
  idRepositoryForThisEMA: number | null = null;
  idRepositoryForNextEMA: number | null = null;

  constructor(init?:Partial<EMA>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMA'] = EMA;

reverseMapping[EMA.name] = 'fr.hiji.metier.ema.entities.EMA';

fields['fr.hiji.metier.ema.entities.EMA']  = {
    idEMA: 'java.lang.Integer',
    idUserObserved: 'java.lang.Integer',
    idUserManager: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    step: 'fr.hiji.metier.ema.entities.EMAStep',
    startExchangeDate: 'java.time.Instant',
    message: 'java.lang.String',
    type: 'fr.hiji.metier.ema.entities.EMAType',
    supportPlan: 'java.lang.String',
    doneWithACoach: 'java.lang.Boolean',
    idRepositoryForThisEMA: 'java.lang.Integer',
    idRepositoryForNextEMA: 'java.lang.Integer'
};
