<div class="d-flex align-items-center" style="min-height: 100vh !important">
  <div class="bg-white rounded shadow-2 p-3 mx-auto" style="z-index: 10">
    <!--<div style="width: 23rem"></div>-->

    <div class="row">
      <div class="col-12 col-md-6 line">
        <img style="max-width: 100%; height: auto; vertical-align: center" src="assets/logout.png" />

        <div class="row mt-3 justify-content-center d-md-none">
          <div class="col-9">
            <hr />
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-center">
        <div class="container-fluid">
          <div class="row text-center justify-content-center">
            <div class="col-auto">
              <div>
                <img style="width: 50px" src="assets/logo.png" />

                <h3 class="mt-2">{{ 'common.logout.Vous voilà déconnecté(e)' | translate }}...</h3>
              </div>
              <div class="mt-2">{{ 'common.logout. mais vous avez le doit de changer d avis' | translate }}😊</div>
            </div>
          </div>
          <div class="row mt-3 text-center justify-content-center-5 mt-2">
            <div class="col">
              <a class="btn btn-primary text-uppercase" routerLink="/">{{ 'common.logout.ME CONNECTER' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
