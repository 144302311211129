import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ic2Module } from '@ic2/ic2-lib';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { EmailEditComponent } from './email-edit.component';

@NgModule({
  declarations: [EmailEditComponent],
  imports: [
    Ic2Module,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    AngularEditorModule,
    CommonModule,
    RouterModule,
    MonacoEditorModule,
    TranslateModule.forChild({ extend: true }),
  ],
  exports: [EmailEditComponent],
})
export class HijiEmailEditModule {}
