import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hiji-reset-password-mail-sent',
  templateUrl: './reset-password-mail-sent.component.html',
  styleUrls: ['./reset-password-mail-sent.component.scss'],
})
export class ResetPasswordMailSentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  submit() {}
}
