// fr.hiji.metier.reporting.services.PdfServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { PDFInfoDTO } from '../entities/PDFInfoDTO';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getInfosPDF(token: string, idReport180Config: number, idOrganisation: number): RpcRequestBuilder<PDFInfoDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PdfService.getInfosPDF';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(token, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idReport180Config, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idOrganisation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reporting.dto.pdf180.PDFInfoDTO');
  }

}
