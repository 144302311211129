// ENTITY fr.hiji.metier.teamboard.dto.TeamBoardBriefDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { TeamBoardBrief } from './TeamBoardBrief';
import { UserSawTeamBoardBrief } from './UserSawTeamBoardBrief';

export class TeamBoardBriefDTO {

  brief: TeamBoardBrief | null = null;
  saw: UserSawTeamBoardBrief[] | null = [];

  constructor(init?:Partial<TeamBoardBriefDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.dto.TeamBoardBriefDTO'] = TeamBoardBriefDTO;

reverseMapping[TeamBoardBriefDTO.name] = 'fr.hiji.metier.teamboard.dto.TeamBoardBriefDTO';

fields['fr.hiji.metier.teamboard.dto.TeamBoardBriefDTO']  = {
    brief: 'fr.hiji.metier.teamboard.entities.TeamBoardBrief',
    saw: 'java.util.List<fr.hiji.metier.teamboard.entities.UserSawTeamBoardBrief>'
};
