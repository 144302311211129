// fr.hiji.metier.certif.services.front.CertificationServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { CertificationSessionCandidatesDTO } from '../entities/CertificationSessionCandidatesDTO';
import { CertificationCandidateResult } from '../entities/CertificationCandidateResult';
import { CertificationSessionCandidateDTO } from '../entities/CertificationSessionCandidateDTO';
import { CertificationResultDTO } from '../entities/CertificationResultDTO';
import { CertificationSessionsDTO } from '../entities/CertificationSessionsDTO';
import { ModalitiesDTO } from '../entities/ModalitiesDTO';
import { CertificationLiteDTO } from '../entities/CertificationLiteDTO';

@Injectable({
  providedIn: 'root',
})
export class CertificationService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCertificationSessions(idCertification: number): RpcRequestBuilder<CertificationSessionsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.getCertificationSessions';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertification, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionsDTO');
  }

  getAvailableCertificationsForUser(): RpcRequestBuilder<CertificationLiteDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.getAvailableCertificationsForUser';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getResults(idCertification: number, idUser: number): RpcRequestBuilder<CertificationResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.getResults';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertification, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.CertificationResultDTO');
  }

  getModalities(idUser: number, idCertificationSession: number): RpcRequestBuilder<ModalitiesDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.getModalities';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idCertificationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.ModalitiesDTO');
  }

  getCandidates(idCertificationSession: number): RpcRequestBuilder<CertificationSessionCandidatesDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.getCandidates';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertificationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidatesDTO');
  }

  addCandidate(idCertificationSession: number, idUser: number): RpcRequestBuilder<CertificationSessionCandidateDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.addCandidate';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertificationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionCandidateDTO');
  }

  removeCandidate(idCertificationSession: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.removeCandidate';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertificationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveCertificationCandidateResult(cc: CertificationCandidateResult): RpcRequestBuilder<CertificationCandidateResult> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.saveCertificationCandidateResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(cc, 'fr.hiji.metier.certif.entities.CertificationCandidateResult', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.entities.CertificationCandidateResult');
  }

  allowCandidateToSeeResultsUpTo(cc: CertificationCandidateResult, allow: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CertificationService.allowCandidateToSeeResultsUpTo';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(cc, 'fr.hiji.metier.certif.entities.CertificationCandidateResult', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(allow, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
