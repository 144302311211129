import { CriteriaType } from '../ic2/entities/CriteriaType';

//Mirroré dans CriteriaType.java
export const criteriaAccountableForSkillStats = [
  CriteriaType.ECHELLE_4,
  CriteriaType.BINAIRE,
  CriteriaType.ECHELLE_5,
  CriteriaType.ECHELLE_10,
  CriteriaType.NPS,
];
