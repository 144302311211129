import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotFoundComponent {
  url;
  constructor(public route: ActivatedRoute) {
    route.url.subscribe((data) => {
      this.url = data;
    });
  }
}
