import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tag } from '../../ic2/entities/Tag';
import { TagManager } from '../../services/tag-manager.service';

@Component({
  selector: 'hiji-tag-display',
  templateUrl: './tag-display.component.html',
  styleUrls: ['./tag-display.component.scss'],
})
export class TagDisplayComponent implements OnInit {
  @Input()
  idTag: number;
  @Input() frontMode: boolean = false;
  @Input() hideDescInfoButton: boolean = false;
  @Input() noWrap: boolean = true;
  @Input() textAfterTagName: string = '';
  @Output() descInfoClick: EventEmitter<Tag> = new EventEmitter<Tag>();

  constructor(public tagManager: TagManager) {}

  ngOnInit(): void {}

  getParents() {
    return this.tagManager.getAllParentsOf(this.idTag, true).reverse();
  }
}
