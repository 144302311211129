// ENTITY fr.hiji.metier.visualize.dto.front.SurveyVisualizeDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { SurveySkillStatDTO } from './SurveySkillStatDTO';
import { SurveyObservableGestureDTO } from './SurveyObservableGestureDTO';
import { Criteria } from './Criteria';

export class SurveyVisualizeDTO {

  idCampaign: number | null = 0;
  campaignName: MultilingualString | null = new MultilingualString();
  campaignDescription: MultilingualString | null = new MultilingualString();
  idRepository: number | null = 0;
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDescription: MultilingualString | null = new MultilingualString();
  nbAnswers: number | null = 0;
  nbMiniUsersStats: number | null = 0;
  withSurveyResultsAvailable: boolean | null = false;
  surveyResultsAvailableDate: Date | null = null;
  hideSkill: boolean | null = false;
  observableGestures: SurveyObservableGestureDTO[] | null = [];
  criterias: Criteria[] | null = [];
  myStats: SurveySkillStatDTO[] | null = [];
  nbOtherStats: number | null = 0;
  otherStats: SurveySkillStatDTO[] | null = [];
  nbEnseigneStats: number | null = 0;
  withEnseigneResults: boolean | null = false;
  enseigneStats: SurveySkillStatDTO[] | null = [];
  nbSocieteStats: number | null = 0;
  withSocieteResults: boolean | null = false;
  societeStats: SurveySkillStatDTO[] | null = [];
  nbRegionStats: number | null = 0;
  withRegionResults: boolean | null = false;
  regionStats: SurveySkillStatDTO[] | null = [];
  nbEtablissementStats: number | null = 0;
  withEtablissementResults: boolean | null = false;
  etablissementStats: SurveySkillStatDTO[] | null = [];

  constructor(init?:Partial<SurveyVisualizeDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.visualize.dto.front.SurveyVisualizeDTO'] = SurveyVisualizeDTO;

reverseMapping[SurveyVisualizeDTO.name] = 'fr.hiji.metier.visualize.dto.front.SurveyVisualizeDTO';

fields['fr.hiji.metier.visualize.dto.front.SurveyVisualizeDTO']  = {
    idCampaign: 'java.lang.Integer',
    campaignName: 'com.ic2.sc.MultilingualString',
    campaignDescription: 'com.ic2.sc.MultilingualString',
    idRepository: 'java.lang.Integer',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDescription: 'com.ic2.sc.MultilingualString',
    nbAnswers: 'java.lang.Integer',
    nbMiniUsersStats: 'java.lang.Integer',
    withSurveyResultsAvailable: 'java.lang.Boolean',
    surveyResultsAvailableDate: 'java.time.LocalDate',
    hideSkill: 'java.lang.Boolean',
    observableGestures: 'java.util.List<fr.hiji.metier.visualize.dto.front.SurveyObservableGestureDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    myStats: 'java.util.List<fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO>',
    nbOtherStats: 'java.lang.Long',
    otherStats: 'java.util.List<fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO>',
    nbEnseigneStats: 'java.lang.Long',
    withEnseigneResults: 'java.lang.Boolean',
    enseigneStats: 'java.util.List<fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO>',
    nbSocieteStats: 'java.lang.Long',
    withSocieteResults: 'java.lang.Boolean',
    societeStats: 'java.util.List<fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO>',
    nbRegionStats: 'java.lang.Long',
    withRegionResults: 'java.lang.Boolean',
    regionStats: 'java.util.List<fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO>',
    nbEtablissementStats: 'java.lang.Long',
    withEtablissementResults: 'java.lang.Boolean',
    etablissementStats: 'java.util.List<fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO>'
};
