// ENTITY fr.hiji.metier.campaign.dto.back.stats.SollicitationPerTargetTypeBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { InquiryTargetType } from './InquiryTargetType';

export class SollicitationPerTargetTypeBODTO {

  targetType: InquiryTargetType | null = null;
  nbSollicitationsAnswered: number | null = 0;
  nbSollicitationsTotal: number | null = 0;

  constructor(init?:Partial<SollicitationPerTargetTypeBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.SollicitationPerTargetTypeBODTO'] = SollicitationPerTargetTypeBODTO;

reverseMapping[SollicitationPerTargetTypeBODTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.SollicitationPerTargetTypeBODTO';

fields['fr.hiji.metier.campaign.dto.back.stats.SollicitationPerTargetTypeBODTO']  = {
    targetType: 'fr.hiji.metier.campaign.entities._360.InquiryTargetType',
    nbSollicitationsAnswered: 'java.lang.Integer',
    nbSollicitationsTotal: 'java.lang.Integer'
};
