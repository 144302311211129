// fr.hiji.metier.reporting.services.ReportDownloadServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ReportDTO } from '../entities/ReportDTO';

@Injectable({
  providedIn: 'root',
})
export class ReportDownloadService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getReports(id: number, recherche: string): RpcRequestBuilder<ReportDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportDownloadService.getReports';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(id, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(recherche, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  downloadReport(path: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ReportDownloadService.downloadReport';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(path, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.nio.file.Path');
  }

}
