import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.endsWith('/rpc')) return next.handle(req);
    try {
      const translateService = this.injector.get(TranslateService);
      let lang = translateService.currentLang;
      if (lang === undefined) lang = translateService.defaultLang;
      if (lang !== undefined) {
        const authReq = req.clone({
          headers: req.headers.set('Locale', lang),
        });
        return next.handle(authReq);
      } else console.log('no current lang for', req);
    } catch (err) {
      console.error(err, req);
    }
    return next.handle(req);
  }
}
