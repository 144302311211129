// ENTITY fr.hiji.metier.reporting.entities.survey.SurveyReportIndiceEngagement generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyReportIndiceEngagement {

  idSurveyReport: number | null = 0;
  nbPersTot: number | null = 0;
  nbPersTresEngage: number | null = 0;
  nbPersEngage: number | null = 0;
  nbPersPeuEngage: number | null = 0;
  nbPersDesengage: number | null = 0;

  constructor(init?:Partial<SurveyReportIndiceEngagement>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.survey.SurveyReportIndiceEngagement'] = SurveyReportIndiceEngagement;

reverseMapping[SurveyReportIndiceEngagement.name] = 'fr.hiji.metier.reporting.entities.survey.SurveyReportIndiceEngagement';

fields['fr.hiji.metier.reporting.entities.survey.SurveyReportIndiceEngagement']  = {
    idSurveyReport: 'java.lang.Integer',
    nbPersTot: 'java.lang.Integer',
    nbPersTresEngage: 'java.lang.Integer',
    nbPersEngage: 'java.lang.Integer',
    nbPersPeuEngage: 'java.lang.Integer',
    nbPersDesengage: 'java.lang.Integer'
};
