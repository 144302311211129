// ENTITY fr.hiji.metier.certif.entities.session.CertificationSessionAssessor generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationSessionAssessor {

  idCertificationSession: number | null = 0;
  idUser: number | null = 0;

  constructor(init?:Partial<CertificationSessionAssessor>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.session.CertificationSessionAssessor'] = CertificationSessionAssessor;

reverseMapping[CertificationSessionAssessor.name] = 'fr.hiji.metier.certif.entities.session.CertificationSessionAssessor';

fields['fr.hiji.metier.certif.entities.session.CertificationSessionAssessor']  = {
    idCertificationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer'
};
