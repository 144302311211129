import { ModalityQuizQuestionResultDTO } from '../ic2/entities/ModalityQuizQuestionResultDTO';

//Existe aussi côté java (QuestionQCMPointsCalculator.java)
export class QuestionQCMPointsCalculator {
  static getTotalPoints(selectedAnswers: number[], mqqr: ModalityQuizQuestionResultDTO) {
    let questionScore = 0;
    let questionTotal = mqqr.answers
      .filter((a) => a.points > 0)
      .map((a) => a.points)
      .reduce((a, b) => a + b, 0);

    if (questionTotal == 0) throw new Error('Total points is 0 for answers of question ' + mqqr.question);

    for (let numAnswer of selectedAnswers) {
      let questionQCMAnswer = mqqr.answers.find((a) => a.numAnswer === numAnswer);

      let points = 0;
      if (!questionQCMAnswer) {
        console.error('Answer ' + numAnswer + ' not found for question ' + mqqr.question);
      } else {
        points = questionQCMAnswer.points;
      }

      if (mqqr.allOrNothing && points <= 0) {
        questionScore = 0;
        break;
      } else questionScore += points;
    }

    if (mqqr.allOrNothing && Math.abs(questionScore - questionTotal) > 0.001) questionScore = 0;

    if (!mqqr.allOrNothing && questionScore < 0) questionScore = 0;

    //L.d(questionScore + '/' + questionTotal);

    let totalPoints = (questionScore / questionTotal) * mqqr.points;

    /*
    L.d(
      'Answers for ' +
        mqqr.question.getOrEnglishOrAny('fr') +
        ' gets ' +
        questionScore +
        '/' +
        questionTotal +
        ' points = ' +
        questionScore / questionTotal +
        ' (points added for the quiz: ' +
        questionScore / questionTotal +
        ' * ' +
        mqqr.points +
        ' = ' +
        totalPoints +
        ')'
    );
    */
    return totalPoints;
  }
}
