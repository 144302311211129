// fr.hiji.metier.certif.services.front.RoleplayModalityServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ModalityRoleplayDTO } from '../entities/ModalityRoleplayDTO';
import { ModalityRoleplayResultDTO } from '../entities/ModalityRoleplayResultDTO';

@Injectable({
  providedIn: 'root',
})
export class RoleplayModalityService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getResult(idExam: number): RpcRequestBuilder<ModalityRoleplayResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RoleplayModalityService.getResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.roleplay.result.ModalityRoleplayResultDTO');
  }

  updateEvaluation(idExam: number, value: number, succeeded: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RoleplayModalityService.updateEvaluation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(value, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(succeeded, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  answerRoleplay(roleplay: ModalityRoleplayDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RoleplayModalityService.answerRoleplay';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(roleplay, 'fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getRoleplayModality(idExam: number): RpcRequestBuilder<ModalityRoleplayDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RoleplayModalityService.getRoleplayModality';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayDTO');
  }

}
