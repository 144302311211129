// DB ENUM fr.hiji.metier.referential.entities.CriteriaType generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class CriteriaType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ECHELLE_4 = new CriteriaType(1, "Echelle à 4 valeurs", "ECHELLE_4");
  static readonly BINAIRE = new CriteriaType(2, "Echelle binaire", "BINAIRE");
  static readonly ECHELLE_5 = new CriteriaType(3, "Echelle à 5 valeurs (Likert)", "ECHELLE_5");
  static readonly NPS = new CriteriaType(4, "NPS", "NPS");
  static readonly QUESTION_OUVERTE = new CriteriaType(5, "Question ouverte", "QUESTION_OUVERTE");
  static readonly ECHELLE_10 = new CriteriaType(6, "Echelle de 0 à 10", "ECHELLE_10");

  static readonly values = [
    CriteriaType.ECHELLE_4,
 
    CriteriaType.BINAIRE,
 
    CriteriaType.ECHELLE_5,
 
    CriteriaType.NPS,
 
    CriteriaType.QUESTION_OUVERTE,
 
    CriteriaType.ECHELLE_10
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of CriteriaType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of CriteriaType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.referential.entities.CriteriaType'] = CriteriaType;
reverseMapping[CriteriaType.name] = 'fr.hiji.metier.referential.entities.CriteriaType';
