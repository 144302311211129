import { Config } from './Config';

export const loadClientStyle = (config: Config) => {
  {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'client-theme';
    style.rel = 'stylesheet';
    const url = new URL(window.location.href);
    const theme = url.searchParams.get('theme');
    if (theme) {
      style.href = theme + '.css';
      head.appendChild(style);
    } else if (config.name === 'CHANEL-PROD' || config.name === 'SKILLSUP-TEST' || config.name === 'SKILLSUP' || config.name === 'SKILLSUP-FASHION') {
      style.href = 'chanel.css';
      head.appendChild(style);
    } else if (config.name === 'DIOR-PROD') {
      style.href = 'dior.css';
      head.appendChild(style);
    } else if (config.name === 'ARMATIS-PROD') {
      style.href = 'armatis.css';
      head.appendChild(style);
    } else if (config.css) {
      style.href = config.css;
      head.appendChild(style);
    }
  }
  {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'custom-theme';
    style.rel = 'stylesheet';
    style.href = config.rpcHost + '/custom.css';

    head.appendChild(style);
  }
};
