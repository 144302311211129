// ENTITY fr.hiji.metier.conf.dto.ModulesDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ModulesDTO {

  moduleCampaign: boolean | null = false;
  coacheeAllowedToExchange: boolean | null = false;
  opinionEnabled: boolean | null = false;
  moduleCampaign360: boolean | null = false;
  infoCollaborator: MultilingualString | null = new MultilingualString();
  infoPair: MultilingualString | null = new MultilingualString();
  infoManager: MultilingualString | null = new MultilingualString();
  infoExternal: MultilingualString | null = new MultilingualString();
  custom360Allowed: boolean | null = false;
  useCampaignAccessControl: boolean | null = false;
  moduleFBS: boolean | null = false;
  moduleBilan: boolean | null = false;
  moduleEnquete: boolean | null = false;
  modulePDI: boolean | null = false;
  manualHierarchicalManagement: boolean | null = false;
  hideHomeTeamButtons: boolean | null = false;
  campaign360ExternalEnabled: boolean | null = false;
  campaign360AllowOnlyOneAnonymousInATargetType: boolean | null = false;
  displayFBSHomeButton: boolean | null = false;
  displayFunctionFront: boolean | null = false;
  fbsReceivedNotifMailEnabled: boolean | null = false;
  fbsSolicitationMailEnabled: boolean | null = false;
  changeBONamesToSurveyMode: boolean | null = false;
  nbMiniUsersStats: number | null = 0;
  moduleCertification: boolean | null = false;
  moduleEvent: boolean | null = false;
  anonymousEventEnabled: boolean | null = false;
  moduleMesureImpactFormation: boolean | null = false;
  idCriteriaAction: number | null = 0;
  idCriteriaExpectation: number | null = 0;
  idCriteriaBusinessExpectation: number | null = 0;
  trainer: boolean | null = false;
  moduleEMA: boolean | null = false;
  emaName: MultilingualString | null = new MultilingualString();
  hasTeam: boolean | null = false;
  allowN2PlusToAuditExchanges: boolean | null = false;
  coachEnabled: boolean | null = false;
  moduleTeamBoard: boolean | null = false;
  hideConnexionDateToGestionnaires: boolean | null = false;
  moduleTMS: boolean | null = false;
  userImportEnabled: boolean | null = false;
  emailUserImportEnabled: boolean | null = false;
  addPopulationToSessionEnabled: boolean | null = false;

  constructor(init?:Partial<ModulesDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.ModulesDTO'] = ModulesDTO;

reverseMapping[ModulesDTO.name] = 'fr.hiji.metier.conf.dto.ModulesDTO';

fields['fr.hiji.metier.conf.dto.ModulesDTO']  = {
    moduleCampaign: 'java.lang.Boolean',
    coacheeAllowedToExchange: 'java.lang.Boolean',
    opinionEnabled: 'java.lang.Boolean',
    moduleCampaign360: 'java.lang.Boolean',
    infoCollaborator: 'com.ic2.sc.MultilingualString',
    infoPair: 'com.ic2.sc.MultilingualString',
    infoManager: 'com.ic2.sc.MultilingualString',
    infoExternal: 'com.ic2.sc.MultilingualString',
    custom360Allowed: 'java.lang.Boolean',
    useCampaignAccessControl: 'java.lang.Boolean',
    moduleFBS: 'java.lang.Boolean',
    moduleBilan: 'java.lang.Boolean',
    moduleEnquete: 'java.lang.Boolean',
    modulePDI: 'java.lang.Boolean',
    manualHierarchicalManagement: 'java.lang.Boolean',
    hideHomeTeamButtons: 'java.lang.Boolean',
    campaign360ExternalEnabled: 'java.lang.Boolean',
    campaign360AllowOnlyOneAnonymousInATargetType: 'java.lang.Boolean',
    displayFBSHomeButton: 'java.lang.Boolean',
    displayFunctionFront: 'java.lang.Boolean',
    fbsReceivedNotifMailEnabled: 'java.lang.Boolean',
    fbsSolicitationMailEnabled: 'java.lang.Boolean',
    changeBONamesToSurveyMode: 'java.lang.Boolean',
    nbMiniUsersStats: 'java.lang.Integer',
    moduleCertification: 'java.lang.Boolean',
    moduleEvent: 'java.lang.Boolean',
    anonymousEventEnabled: 'java.lang.Boolean',
    moduleMesureImpactFormation: 'java.lang.Boolean',
    idCriteriaAction: 'java.lang.Integer',
    idCriteriaExpectation: 'java.lang.Integer',
    idCriteriaBusinessExpectation: 'java.lang.Integer',
    trainer: 'java.lang.Boolean',
    moduleEMA: 'java.lang.Boolean',
    emaName: 'com.ic2.sc.MultilingualString',
    hasTeam: 'java.lang.Boolean',
    allowN2PlusToAuditExchanges: 'java.lang.Boolean',
    coachEnabled: 'java.lang.Boolean',
    moduleTeamBoard: 'java.lang.Boolean',
    hideConnexionDateToGestionnaires: 'java.lang.Boolean',
    moduleTMS: 'java.lang.Boolean',
    userImportEnabled: 'java.lang.Boolean',
    emailUserImportEnabled: 'java.lang.Boolean',
    addPopulationToSessionEnabled: 'java.lang.Boolean'
};
