// fr.hiji.metier.mif.services.back.formation.FormationQuizBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationQuizBODTO } from '../entities/FormationQuizBODTO';

@Injectable({
  providedIn: 'root',
})
export class FormationQuizBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFormationQuizs(idFormation: number): RpcRequestBuilder<FormationQuizBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationQuizBOService.getFormationQuizs';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  saveFormationQuizs(dtos: FormationQuizBODTO[]): RpcRequestBuilder<FormationQuizBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationQuizBOService.saveFormationQuizs';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dtos, 'java.util.List<fr.hiji.metier.mif.dto.back.formationQuiz.FormationQuizBODTO>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
