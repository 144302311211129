// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationSessionParticipantAction {

  idFormationSessionParticipantAction: number | null = 0;
  idFormationSession: number | null = 0;
  idUser: number | null = 0;
  action: string | null = '';
  noteParticipant10DaysAfter: number | null = 0;
  observedParticipant10DaysAfter: boolean | null = false;
  idCriteria10DaysAfter: number | null = 0;
  observationDate10DaysAfter: Date | null = null;
  noteParticipant42DaysAfter: number | null = 0;
  observedParticipant42DaysAfter: boolean | null = false;
  idCriteria42DaysAfter: number | null = 0;
  observationDate42DaysAfter: Date | null = null;
  noteManager45DaysAfter: number | null = 0;
  observedManager45DaysAfter: boolean | null = false;
  idCriteria45DaysAfter: number | null = 0;
  observationDate45DaysAfter: Date | null = null;

  constructor(init?:Partial<FormationSessionParticipantAction>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction'] = FormationSessionParticipantAction;

reverseMapping[FormationSessionParticipantAction.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction';

fields['fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction']  = {
    idFormationSessionParticipantAction: 'java.lang.Integer',
    idFormationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    action: 'java.lang.String',
    noteParticipant10DaysAfter: 'java.lang.Integer',
    observedParticipant10DaysAfter: 'java.lang.Boolean',
    idCriteria10DaysAfter: 'java.lang.Integer',
    observationDate10DaysAfter: 'java.time.Instant',
    noteParticipant42DaysAfter: 'java.lang.Integer',
    observedParticipant42DaysAfter: 'java.lang.Boolean',
    idCriteria42DaysAfter: 'java.lang.Integer',
    observationDate42DaysAfter: 'java.time.Instant',
    noteManager45DaysAfter: 'java.lang.Integer',
    observedManager45DaysAfter: 'java.lang.Boolean',
    idCriteria45DaysAfter: 'java.lang.Integer',
    observationDate45DaysAfter: 'java.time.Instant'
};
