<hiji-wavy-container>
  <div class="container bg-white rounded shadow-2 py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row">
      <div class="col-auto text-end d-none d-md-flex align-items-center">
        <img class="w-100" src="assets/common/reset-pwd-form.jpg" alt="reset password image" />
      </div>
      <div class="col">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-auto">
              <img style="width: 75px" src="assets/logo.png" />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12 text-center justify-content-center">
              <div class="col">
                <h4>{{ 'common.reset-password-form.La dernière étape' | translate }}</h4>
                <p class="mt-2">
                  {{ 'common.reset-password-form.Dernière ligne droite ! Choisissez votre mot de passe pour devenir un super collaborateur !' | translate }}
                </p>
              </div>
            </div>
          </div>
          <form #f="ngForm" [formGroup]="form" (ngSubmit)="submit()">
            <div class="row mt-4">
              <div class="col">
                <div class="position-relative">
                  <input
                    class="form-control input-with-icon"
                    type="password"
                    [placeholder]="'common.reset-password-form.Mot de passe' | translate"
                    formControlName="password"
                    autocomplete="new-password"
                    #p
                  />
                  <div class="input-icon" (click)="p.type === 'password' ? (p.type = 'text') : (p.type = 'password')">
                    <i class="fa-light" [class.fa-eye]="p.type === 'text'" [class.fa-eye-slash]="p.type === 'password'"></i>
                  </div>
                </div>
                <div
                  class="my-fs-1"
                  [class.text-danger]="form.get('password').touched && !form.hasError('required', 'password') && form.hasError('pattern', 'password')"
                  [class.text-success]="form.get('password').touched && !form.hasError('required', 'password') && !form.hasError('pattern', 'password')"
                >
                  <i class="fa-light fa-info-circle"></i>
                  {{
                    'common.signup.Au minimum 8 caractères avec au moins 3 des 4 critères suivants : 1 chiffre, 1 majuscule, 1 minuscule, un caractère spécial'
                      | translate
                  }}
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <div class="position-relative">
                  <input
                    class="form-control input-with-icon"
                    type="password"
                    [placeholder]="'common.reset-password-form.Confirmer le mot de passe' | translate"
                    formControlName="passwordConfirm"
                    autocomplete="new-password"
                    #p2
                  />
                  <div class="input-icon" (click)="p2.type === 'password' ? (p2.type = 'text') : (p2.type = 'password')">
                    <i class="fa-light" [class.fa-eye]="p2.type === 'text'" [class.fa-eye-slash]="p2.type === 'password'"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3" *ngIf="error || (f.submitted && !form.valid)">
              <div class="col">
                <div class="errors">
                  <ul class="text-start">
                    <li *ngIf="form.hasError('required', 'password')">{{ 'common.reset-password-form.Le mot de passe est requis' | translate }}</li>
                    <li *ngIf="form.hasError('pattern', 'password')">{{ 'common.signup.Le mot de passe ne respecte pas les contraintes' | translate }}</li>
                    <li *ngIf="form.hasError('required', 'passwordConfirm')">
                      {{ 'common.reset-password-form.Vous devez confirmer le mot de passe' | translate }}
                    </li>
                    <li *ngIf="form.hasError('minlength', 'password')">{{ 'common.reset-password-form.Le mot de passe est trop court' | translate }}</li>
                    <li *ngIf="form.hasError('notSame')">{{ 'common.reset-password-form.Les deux mots de passe ne correspondent pas' | translate }}</li>
                    <li *ngIf="error">{{ 'common.reset-password-form.Le lien est expiré (Token invalide)' | translate }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col text-center">
                <button class="btn btn-primary text-uppercase w-100" type="submit" [disabled]="loading">
                  {{ 'common.reset-password-form.Valider' | translate }}
                  <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</hiji-wavy-container>
