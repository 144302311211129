import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
export interface RadarData {
  name: string;
  color: string;
  background: boolean;
  values: RadarValue[];
}
export interface RadarValue {
  axis: string;
  value: number;
  tooltip?: string;
  color: string;
  data?: any;
}
@Component({
  selector: 'hiji-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss'],
})
export class RadarChartComponent implements OnChanges, AfterViewInit {
  @Input()
  data: RadarData[] = [];
  @Input()
  canBeExpanded: (data: any) => boolean = (data) => false;
  @ViewChild('el')
  el: ElementRef;
  @Output()
  titleClick: EventEmitter<any> = new EventEmitter<any>();
  id: string = 'radar-chart-' + Math.floor(Math.random() * 1000000);

  constructor(private decimalPipe: DecimalPipe) {}

  ngAfterViewInit(): void {
    this.redraw();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) this.redraw();
  }

  redraw() {
    this.data.sort((a, b) => a.name.localeCompare(b.name)); //on trie les courbes

    this.data.forEach((d) => {
      if (d.color && d.color.startsWith('var(--')) {
        const colorVariable = d.color.match(/var\((.+?)\)/);
        if (colorVariable) {
          const propertyName = colorVariable[1];
          d.color = getComputedStyle(document.body).getPropertyValue(propertyName);
        }
      }
    }); //lors du téléchargement du radar il ne faut pas de variables css

    //On compute les données manquantes avec des value:null pour éviter un décalage des valeurs par rapport aux axes
    const maxValues = this.data.map((d) => d.values.length);
    const maxValuesIndex = maxValues.indexOf(Math.max(...maxValues));
    const maxValuesLength = this.data[maxValuesIndex].values.length;
    this.data.forEach((d) => {
      if (d.values.length < maxValuesLength) {
        const missingValues = this.data[maxValuesIndex].values.filter((v) => d.values.find((dv) => dv.axis === v.axis) === undefined);
        d.values.push(...missingValues.map((v) => ({ axis: v.axis, value: null, color: v.color })));
      }
    });

    this.data.forEach((d) => d.values.sort((a, b) => a.axis.localeCompare(b.axis))); //on trie les axes

    //Call function to draw the Radar chart
    if (this.el && this.el.nativeElement) this.RadarChart();
  }

  RadarChart() {
    const cfg = {
      w: 600, //Width of the circle
      h: 600, //Height of the circle
      margin: { top: 50, right: 50, bottom: 50, left: 50 }, //The margins of the SVG
      levels: 4, //How many levels or inner circles should there be drawn
      maxValue: 100, //What is the value that the biggest circle will represent
      labelFactor: 1.1, //How much farther than the radius of the outer circle should the labels be placed
      wrapWidth: 65, //The number of pixels after which a label needs to be given a new line
      opacityArea: 0.35, //The opacity of the area of the blob
      dotRadius: 4, //The size of the colored circles of each blog
      opacityCircles: 0.1, //The opacity of the circles of each blob
      strokeWidth: 2, //The width of the stroke around each blob
      roundStrokes: true, //If true the area and stroke will follow a round path (cardinal-closed)
      color: d3.scaleOrdinal(d3.schemeCategory10), //Color function
    };

    //Put all of the options into a variable called cfg
    /*
    if ('undefined' !== typeof options) {
      for (const i in options) {
        if ('undefined' !== typeof options[i]) {
          cfg[i] = options[i];
        }
      } //for i
    } //if
    */
    //If the supplied maxValue is smaller than the actual one, replace by the max in the data
    let maxValue = Math.max(
      cfg.maxValue,
      this.data.length > 0
        ? d3.max(this.data, (i) => {
            return d3.max(
              i.values.map((o) => {
                return o.value;
              })
            );
          })
        : 0
    );
    if (Number.isNaN(maxValue)) maxValue = cfg.maxValue;

    let allAxis = [{ name: '', color: null, data: null }];
    if (this.data.length > 0) {
      //find the data item wich has the most values
      const maxValues = this.data.map((d) => d.values.length);
      const maxValuesIndex = maxValues.indexOf(Math.max(...maxValues));
      allAxis = this.data[maxValuesIndex].values.map((i, j) => {
        let c = i.color;

        if (c && c.startsWith('var(--')) {
          const colorVariable = c.match(/var\((.+?)\)/);
          if (colorVariable) {
            const propertyName = colorVariable[1];
            c = getComputedStyle(document.body).getPropertyValue(propertyName);
          }
        }
        return { name: i.axis, color: c, data: i.data };
      });
    }
    //Names of each axis
    const total = allAxis.length; //The number of different axes
    const radius = Math.min(cfg.w / 2, cfg.h / 2) * 0.9; //Radius of the outermost circle
    const Format = d3.format('%'); //Percentage formatting
    const angleSlice = (Math.PI * 2) / total; //The width in radians of each "slice"

    //Scale for the radius
    const rScale = d3.scaleLinear().range([0, radius]).domain([0, maxValue]);

    /////////////////////////////////////////////////////////
    //////////// Create the container SVG and g /////////////
    /////////////////////////////////////////////////////////

    //Remove whatever chart with the same id/class was present before
    d3.select(this.el.nativeElement).select('svg').remove();

    //Initiate the radar chart SVG
    const svg = d3
      .select(this.el.nativeElement)
      .append('svg')
      /*
			.attr("width",  cfg.w + cfg.margin.left + cfg.margin.right)
			.attr("height", cfg.h + cfg.margin.top + cfg.margin.bottom)
      */
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', '0 0 ' + (cfg.w + cfg.margin.left + cfg.margin.right) + ' ' + (cfg.h + cfg.margin.top + cfg.margin.bottom));
    //Append a g element
    const g = svg.append('g').attr('transform', 'translate(' + (cfg.w / 2 + cfg.margin.left) + ',' + (cfg.h / 2 + cfg.margin.top) + ')');

    /////////////////////////////////////////////////////////
    ////////// Glow filter for some extra pizzazz ///////////
    /////////////////////////////////////////////////////////

    //Filter for the outside glow
    const filter = g.append('defs').append('filter').attr('id', 'glow'),
      feGaussianBlur = filter.append('feGaussianBlur').attr('stdDeviation', '2.5').attr('result', 'coloredBlur'),
      feMerge = filter.append('feMerge'),
      feMergeNode_1 = feMerge.append('feMergeNode').attr('in', 'coloredBlur'),
      feMergeNode_2 = feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    /////////////////////////////////////////////////////////
    /////////////// Draw the Circular grid //////////////////
    /////////////////////////////////////////////////////////

    //Wrapper for the grid & axes
    const axisGrid = g.append('g').attr('class', 'axisWrapper');

    //Draw the background circles
    axisGrid
      .selectAll('.levels')
      .data(d3.range(1, cfg.levels + 1).reverse())
      .enter()
      .append('circle')
      .attr('class', 'gridCircle')
      .attr('r', (d, i) => {
        return (radius / cfg.levels) * d;
      })
      .style('fill', '#CDCDCD')
      .style('stroke', '#CDCDCD')
      .style('fill-opacity', cfg.opacityCircles)
      .style('filter', 'url(#glow)');

    //Text indicating at what % each level is
    /*
    axisGrid
      .selectAll('.axisLabel')
      .data(d3.range(1, cfg.levels + 1).reverse())
      .enter()
      .append('text')
      .attr('class', 'axisLabel')
      .attr('x', 4)
      .attr('y', (d) => {
        return (-d * radius) / cfg.levels;
      })
      .attr('dy', '0.4em')
      .style('font-size', '10px')
      .attr('fill', '#737373')
      .text(function (d, i) {
        return Format((maxValue * d) / cfg.levels);
      });
*/
    /////////////////////////////////////////////////////////
    //////////////////// Draw the axes //////////////////////
    /////////////////////////////////////////////////////////

    if (this.data.length === 0) return;

    //Create the straight lines radiating outward from the center
    const axis = axisGrid.selectAll('.axis').data(allAxis).enter().append('g').attr('class', 'axis');
    //Append the lines
    axis
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', function (d, i) {
        return rScale(maxValue * 1.1) * Math.cos(angleSlice * i - Math.PI / 2);
      })
      .attr('y2', function (d, i) {
        return rScale(maxValue * 1.1) * Math.sin(angleSlice * i - Math.PI / 2);
      })
      .attr('class', 'line')
      .style('stroke', 'white')
      .style('stroke-width', '2px');

    //Append the labels at each axis
    axis
      .append('text')
      .on('click', (event, data) => {
        //console.log('click radar title', event, data);
        this.titleClick.emit(data.data);
      })
      .attr('class', (data, idx) => {
        return 'legend fw-bold' + (this.canBeExpanded(data.data) ? ' pointer' : '');
      })
      .style('font-size', '10px')
      .style('fill', function (d, i) {
        return d.color;
      })
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .attr('x', function (d, i) {
        return rScale(maxValue * cfg.labelFactor) * Math.cos(angleSlice * i - Math.PI / 2);
      })
      .attr('y', function (d, i) {
        return rScale(maxValue * cfg.labelFactor) * Math.sin(angleSlice * i - Math.PI / 2);
      })
      .text(function (d: any) {
        return d.name;
      })
      .call(this.wrap, cfg.wrapWidth);

    /////////////////////////////////////////////////////////
    ///////////// Draw the radar chart blobs ////////////////
    /////////////////////////////////////////////////////////

    //The radial line function
    const radarLine = d3
      .lineRadial()
      .curve(d3.curveBasisClosed)
      //.defined((d: any /*RadarValue*/) => {
      //  return d.value !== null;
      //})
      .radius((d: any) => {
        return d.value === null ? null : rScale(d.value);
      })
      .angle(function (d, i) {
        return i * angleSlice;
      });

    if (cfg.roundStrokes) {
      radarLine.curve(d3.curveCardinalClosed);
    }

    //Create a wrapper for the blobs
    const blobWrapper = g
      .selectAll('.radarWrapper')
      .data(this.data.map((d) => d))
      .enter()
      .append('g')
      .attr('class', 'radarWrapper');

    //Append the backgrounds
    blobWrapper
      .append('path')
      .filter((d) => d.background)
      .attr('class', 'radarArea')
      .attr('id', this.id)
      .attr('d', function (d: any, i) {
        return radarLine(d.values);
      })
      .style('fill', function (d, i: any) {
        return d.color;
      })
      .style('fill-opacity', cfg.opacityArea)
      .on('mouseover', function (d, i) {
        //Dim all blobs
        d3.selectAll('.radarArea#' + this.id)
          .transition()
          .duration(200)
          .style('fill-opacity', 0.1);
        //Bring back the hovered over blob
        d3.select(this).transition().duration(200).style('fill-opacity', 0.7);
      })
      .on('mouseout', function () {
        //Bring back all blobs
        d3.selectAll('.radarArea#' + this.id)
          .transition()
          .duration(200)
          .style('fill-opacity', cfg.opacityArea);
      });

    //Create the outlines
    blobWrapper
      .append('path')
      .attr('class', 'radarStroke')
      .attr('d', function (d: any, i) {
        return radarLine(d.values);
      })
      .style('stroke-width', cfg.strokeWidth + 'px')
      .style('stroke', function (d, i: any) {
        return d.color;
      })
      .style('fill', 'none')
      .style('filter', 'url(#glow)');

    //Append the circles
    blobWrapper
      .selectAll('.radarCircle')
      .data((d, i) => {
        return d.values.map((v: any) => {
          v.parent = d;
          return v;
        });
      })
      .enter()
      .filter((d: any) => d.value !== null)
      .append('circle')
      .attr('class', 'radarCircle')
      .attr('r', cfg.dotRadius)
      .attr('cx', function (d: any, i) {
        const index = d.parent.values.indexOf(d); //On ne prends pas i car il peut y avoir des null dans un dataset et pas dans un autre et comme on filter juste avant, on a pas le bon index
        return rScale(d.value) * Math.cos(angleSlice * index - Math.PI / 2);
      })
      .attr('cy', function (d: any, i) {
        const index = d.parent.values.indexOf(d);
        return rScale(d.value) * Math.sin(angleSlice * index - Math.PI / 2);
      })
      .style('fill', (d, i, nodes: any) => {
        return d.parent.color; //cfg.color(this.data.indexOf(d.parent) as any);
      })
      .style('fill-opacity', 0.8);

    /////////////////////////////////////////////////////////
    //////// Append invisible circles for tooltip ///////////
    /////////////////////////////////////////////////////////

    //Wrapper for the invisible circles on top
    const blobCircleWrapper = g
      .selectAll('.radarCircleWrapper')
      .data(this.data.map((d) => d.values))
      .enter()
      .append('g')
      .attr('class', 'radarCircleWrapper');

    const that = this;

    //Append a set of invisible circles on top for the mouseover pop-up
    blobCircleWrapper
      .selectAll('.radarInvisibleCircle')
      .data(function (d: any, i) {
        return d;
      })
      .enter()
      .filter((d: any) => d.value !== null)
      .append('circle')
      .attr('class', 'radarInvisibleCircle')
      .attr('r', cfg.dotRadius * 1.5)
      .attr('cx', function (d: any, i) {
        const index = d.parent.values.indexOf(d);
        return rScale(d.value) * Math.cos(angleSlice * index - Math.PI / 2);
      })
      .attr('cy', function (d: any, i) {
        const index = d.parent.values.indexOf(d);
        return rScale(d.value) * Math.sin(angleSlice * index - Math.PI / 2);
      })
      .attr('data-value', function (d: any, i) {
        return d.value;
      })
      .attr('data-tooltip', function (d: any, i) {
        return d.tooltip;
      })
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', function (d, i) {
        const newX = parseFloat(d3.select(this).attr('cx'));
        const newY = parseFloat(d3.select(this).attr('cy'));

        tooltip
          .attr('x', newX - 5)
          .attr('y', newY - 16) //la position du texte est située au milieu du texte, donc on décale de la "moitié" de la hauteur
          .text(d.target.getAttribute('data-tooltip') ?? that.decimalPipe.transform(parseFloat(d.target.getAttribute('data-value')), '1.0-2'))
          .style('font-size', '24px')
          .transition()
          .duration(200)
          .style('opacity', 1);

        tooltipBg
          .attr('x', newX - 10)
          .attr('y', newY - 40)
          .attr('width', tooltip.node().getComputedTextLength() + 10)
          .attr('height', 30)
          .transition()
          .duration(200)
          .style('opacity', 1);
      })
      .on('mouseout', function () {
        tooltip.transition().duration(200).style('opacity', 0);
        tooltipBg.transition().duration(200).style('opacity', 0);
      });

    //Set up the small tooltip for when you hover over a circle
    const tooltipBg = g.append('rect').attr('rx', 5).attr('ry', 5).style('fill', 'white').attr('class', 'tooltip-bg').style('opacity', 0);
    const tooltip = g.append('text').attr('class', 'tooltip').style('opacity', 0);
  }
  //Taken from http://bl.ocks.org/mbostock/7555321
  //Wraps SVG text
  wrap(t, width) {
    t.each(function () {
      const text = d3.select(this);
      const words = text
        .text()
        .split(/\s+/)
        .filter((word) => word) // car quand ca commence par un espace ca donne des mots undefined
        .reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.4; // ems
      const y = text.attr('y');
      const x = text.attr('x');
      const dy = parseFloat(text.attr('dy'));
      let tspan = text
        .text(null)
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em');

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(' '));
        //width*1.25 pour split le mot en deux
        if (tspan.node().getComputedTextLength() > width * 1.25 && line.length === 1) {
          line.pop();
          tspan.text(line.join(' ')); //on cloture la ligne precedente si il y a
          //console.log('WORD TOO LONG', word);
          tspan = text
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text('temp');
          //how much letter to remove so it fits ?
          let letterToRemove = 0;
          do {
            letterToRemove++;
            tspan.text(word.substring(0, word.length - letterToRemove));
            //console.log('test length of ', word.substring(0, word.length - letterToRemove), tspan.node().getComputedTextLength(), width * 1.25);
          } while (tspan.node().getComputedTextLength() > width * 1.25 && letterToRemove < word.length);

          line = ['-' + word.substring(word.length - letterToRemove, word.length)];
          tspan = text
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(line.join(''));
        } else if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word);
        }
      }
    });
  } //wrap
}
