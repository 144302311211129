import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ClientEnv } from '../../services/client.env.service';

@Component({
  selector: 'hiji-sso-error',
  templateUrl: './sso-error.component.html',
  styleUrls: ['./sso-error.component.scss'],
})
export class SsoErrorComponent implements OnInit {
  error; //no_user ou user_deleted voir SSOService
  email;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private oauthService: OAuthService,
    private clientEnv: ClientEnv
  ) {
    this.error = route.snapshot.params['error'];
    this.email = route.snapshot.params['email'];
    if (this.error === 'no_user' && clientEnv.isAnyOf(clientEnv.DARTY, clientEnv.FNAC)) {
      this.error = 'fnacdarty_no_user';
    }
  }

  ngOnInit(): void {}

  retry() {
    this.oauthService.logOut();
    localStorage.setItem('forcelogin', 'true');
    this.router.navigate(['/']);
  }
}
