// ENTITY fr.hiji.metier.reporting.dto.pdf180.ReportDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ReportDTO {

  name: string | null = '';
  path: string | null = '';
  creationDate: Date | null = null;
  folder: boolean | null = false;
  children: ReportDTO[] | null = [];

  constructor(init?:Partial<ReportDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.pdf180.ReportDTO'] = ReportDTO;

reverseMapping[ReportDTO.name] = 'fr.hiji.metier.reporting.dto.pdf180.ReportDTO';

fields['fr.hiji.metier.reporting.dto.pdf180.ReportDTO']  = {
    name: 'java.lang.String',
    path: 'java.lang.String',
    creationDate: 'java.time.Instant',
    folder: 'java.lang.Boolean',
    children: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.ReportDTO>'
};
