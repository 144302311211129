// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleMesureImpactFormationDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleMesureImpactFormationDTO {

  moduleMesureImpactFormationEnabled: boolean | null = false;
  idCriteriaAction: number | null = 0;
  idCriteriaExpectation: number | null = 0;
  idCriteriaBusinessExpectation: number | null = 0;
  addPopulationToSessionEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleMesureImpactFormationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleMesureImpactFormationDTO'] = ModuleMesureImpactFormationDTO;

reverseMapping[ModuleMesureImpactFormationDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleMesureImpactFormationDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleMesureImpactFormationDTO']  = {
    moduleMesureImpactFormationEnabled: 'java.lang.Boolean',
    idCriteriaAction: 'java.lang.Integer',
    idCriteriaExpectation: 'java.lang.Integer',
    idCriteriaBusinessExpectation: 'java.lang.Integer',
    addPopulationToSessionEnabled: 'java.lang.Boolean'
};
