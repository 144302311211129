import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MsPipe } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Criteria, CriteriaType, ObservableGestureStatDTO } from '../../../ic2/entities/entities';

@Component({
  selector: 'hiji-stats-og-chart-criteria245',
  templateUrl: './stats-og-chart-criteria245.component.html',
  styleUrls: ['./stats-og-chart-criteria245.component.scss'],
})
export class StatsOgChartCriteria245Component implements OnInit {
  @Input()
  observableGesture: Partial<ObservableGestureStatDTO>;
  @Input()
  criteria: Criteria;
  @Input()
  showClose: boolean = true;
  @Output() close: EventEmitter<any> = new EventEmitter();

  colors = {
    rouge: '#ed4731',
    orange: '#ed7d31',
    jaune: '#ffcb05',
    vertclair: '#92d050',
    vertfonce: '#70ad47',
    gris: '#c2c4d6',
  };

  barThickness: 20;
  pieChartPlugins = [];

  chartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [],
  };

  chartOptions: any /*ChartConfiguration*/ = {
    indexAxis: 'y',
    hover: {
      mode: 'nearest',
      intersect: true,
      axis: 'y',
    },
    animation: {
      duration: 10,
    },

    responsive: true,
    scales: {
      x: {
        stacked: true,
        max: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 20,
          max: 100,
        },
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] !== 0;
        },
        formatter: function (value, context) {
          return Math.round(value) + '%';
        },
      },
    },
  };

  chartType: ChartType = 'bar';

  constructor(private translate: TranslateService, private msPipe: MsPipe) {
    this.pieChartPlugins = [ChartDataLabels];
  }

  ngOnInit(): void {
    if (this.criteria.type === CriteriaType.BINAIRE) {
      this.chartData = {
        labels: [this.msPipe.transform(this.observableGesture.observableGestureName)],
        datasets: [
          {
            data: [(this.observableGesture.nbValue5 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label5),
            borderColor: this.colors.vertfonce,
            backgroundColor: this.colors.vertfonce,
            hoverBackgroundColor: this.colors.vertfonce,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue1 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label1),
            borderColor: this.colors.rouge,
            backgroundColor: this.colors.rouge,
            hoverBackgroundColor: this.colors.rouge,
            barThickness: this.barThickness,
          },
        ],
      };
    } else if (this.criteria.type === CriteriaType.ECHELLE_4) {
      this.chartData = {
        labels: [this.msPipe.transform(this.observableGesture.observableGestureName)],
        datasets: [
          {
            data: [(this.observableGesture.nbValue4 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label4),
            borderColor: this.colors.vertfonce,
            backgroundColor: this.colors.vertfonce,
            hoverBackgroundColor: this.colors.vertfonce,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue3 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label3),
            borderColor: this.colors.vertclair,
            backgroundColor: this.colors.vertclair,
            hoverBackgroundColor: this.colors.vertclair,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue2 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label2),
            borderColor: this.colors.jaune,
            backgroundColor: this.colors.jaune,
            hoverBackgroundColor: this.colors.jaune,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue1 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label1),
            borderColor: this.colors.orange,
            backgroundColor: this.colors.orange,
            hoverBackgroundColor: this.colors.orange,
            barThickness: this.barThickness,
          },
        ],
      };
    } else if (this.criteria.type === CriteriaType.ECHELLE_5) {
      this.chartData = {
        labels: [this.msPipe.transform(this.observableGesture.observableGestureName)],
        datasets: [
          {
            data: [(this.observableGesture.nbValue5 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label5),
            borderColor: this.colors.vertfonce,
            backgroundColor: this.colors.vertfonce,
            hoverBackgroundColor: this.colors.vertfonce,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue4 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label4),
            borderColor: this.colors.vertclair,
            backgroundColor: this.colors.vertclair,
            hoverBackgroundColor: this.colors.vertclair,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue3 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label3),
            borderColor: this.colors.gris,
            backgroundColor: this.colors.gris,
            hoverBackgroundColor: this.colors.gris,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue2 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label2),
            borderColor: this.colors.orange,
            backgroundColor: this.colors.orange,
            hoverBackgroundColor: this.colors.orange,
            barThickness: this.barThickness,
          },
          {
            data: [(this.observableGesture.nbValue1 / this.observableGesture.nb) * 100],
            label: this.msPipe.transform(this.criteria.label1),
            borderColor: this.colors.rouge,
            backgroundColor: this.colors.rouge,
            hoverBackgroundColor: this.colors.rouge,
            barThickness: this.barThickness,
          },
        ],
      };
    } else {
      throw new Error('Non géré critere type ' + this.criteria.type.lib);
    }
  }

  getAssignedTags() {
    return this.observableGesture.tags.filter((tag) => tag.assigned);
  }
}
