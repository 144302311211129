// ENTITY fr.hiji.metier.certif.dto.back.CertificationBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Certification } from './Certification';

export class CertificationBODTO {

  certification: Certification | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  deleteImage: Boolean | null = null;

  constructor(init?:Partial<CertificationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.CertificationBODTO'] = CertificationBODTO;

reverseMapping[CertificationBODTO.name] = 'fr.hiji.metier.certif.dto.back.CertificationBODTO';

fields['fr.hiji.metier.certif.dto.back.CertificationBODTO']  = {
    certification: 'fr.hiji.metier.certif.entities.Certification',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    deleteImage: 'java.lang.Boolean'
};
