import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EnvService } from '@ic2/ic2-lib';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { HijiBundle } from '../ic2/entities/HijiBundle';
import { AutoLogService } from '../ic2/services/AutoLogService';
import { CoreService } from '../ic2/services/CoreService';
import { AuthService } from '../services/auth.service';
import { Config } from '../tools/Config';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public redirectLoginRoute = '/login';
  public backoffice = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private coreService: CoreService,
    private cookieService: CookieService,
    private env: EnvService<Config>,
    private autoLogService: AutoLogService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;
    //console.log('canActivate', route, state);
    return this.check(route, url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;
    //console.log('canActivateChild', route, state);
    return this.check(route, url);
  }
  storeRedirectUrl(url: string) {
    if (
      url !== '/update' &&
      url !== '/' &&
      url !== '/maj' &&
      url !== '/login' &&
      url !== '/gdpr' &&
      url !== '/logout' &&
      url !== '/sso' &&
      url !== '/mail-auth' &&
      url !== '/autolog' &&
      url !== '/tom-auth' &&
      !url.startsWith('sso-error')
    ) {
      localStorage.setItem('redirectUrl', url); //only used for sso connexion since it change domains and reload the app (login-sso component)
      console.log('storing url to later redirect', url);
    }
  }

  check(route: ActivatedRouteSnapshot, url: string): Observable<boolean | UrlTree> {
    if (window.location.hash.length > 0 && window.location.hash && window.location.hash.indexOf('#token=') !== -1) {
      const authToken = window.location.hash.substring(window.location.hash.indexOf('#token=') + '#token='.length);
      console.log('token found in url');
      window.location.hash = '';
      const defaultObserver = this.getObservable(url);
      return new Observable((observer) => {
        this.authService.getBundleSubscription = this.autoLogService
          .loginAs(authToken)
          .defaultOnErrorAnd((err) => {
            console.error(err);

            observer.error(err);
          })
          .execute((data) => {
            this.authService.loginWith(data, () => {
              defaultObserver.subscribe(observer);
            });
          });
      });
    }
    if (window.location.hash.length > 0 && window.location.hash && window.location.hash.indexOf('#code=') !== -1) {
      this.authService.code = window.location.hash.substring(window.location.hash.indexOf('#code=') + '#code='.length);
      console.log('code found in url');
      window.location.hash = '';
    }
    if (window.location.hash.length > 0 && window.location.hash && window.location.hash.indexOf('#code-event=') !== -1) {
      this.authService.codeEvent = window.location.hash.substring(window.location.hash.indexOf('#code-event=') + '#code-event='.length);
      console.log('code-event found in url');
      window.location.hash = '';
    }
    //const url = urlWithHash; //.substring(0, urlWithHash.indexOf('#'));
    return this.getObservable(url);
  }

  getObservable(url): Observable<boolean | UrlTree> {
    return new Observable((observer) => {
      this.authService.isLoggedIn().subscribe({
        next: (loggedIn) => {
          //console.log(this.authService.userBundle);
          //console.log('login',loggedIn);
          //console.log(route);
          if (!loggedIn) {
            // Store the attempted URL for redirecting
            //window.location.href a la place de url pour retirer le hash (bug lorsqu'on réponds a une enquete et qu'on doit valider la rgpd, le code restait)
            this.storeRedirectUrl(window.location.pathname);
            console.log('redirecting to auth url', this.redirectLoginRoute);

            observer.next(this.router.parseUrl(this.redirectLoginRoute));
          } else if (
            !this.backoffice &&
            this.authService.userBundle !== null &&
            !(<HijiBundle>this.authService.userBundle.data).hijiUser.updateMessageSeen &&
            url !== '/maj' &&
            url !== '/gdpr' &&
            localStorage.getItem('hijiStaff') === null
          ) {
            this.storeRedirectUrl(window.location.pathname);
            console.log('redirecting to maj');
            observer.next(this.router.parseUrl('/maj'));
          } else if (
            this.env.configuration.forceGDPRAcceptation &&
            this.authService.userBundle !== null &&
            !(<HijiBundle>this.authService.userBundle.data).hijiUser.RGPDValidated &&
            url !== '/maj' &&
            url !== '/gdpr' &&
            localStorage.getItem('hijiStaff') === null
          ) {
            this.storeRedirectUrl(window.location.pathname);
            console.log('redirecting to gdpr');
            observer.next(this.router.parseUrl('/gdpr'));
          } else {
            console.log('allow access to', url);
            observer.next(true);
          }
          /*
        else if (
          this.authService.has(DegineoRight.CLIENT) &&
          !this.authService.userBundle.du.signupFinished &&
          route.routeConfig.path !== 'configuration-compte'
        ) {
          //console.log('SHOULD NAVIGATE');
          this.router.navigate(['/configuration-compte']);
        }
        */
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }
}
