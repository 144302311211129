// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventItemBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationEventTag } from './FormationEventTag';

export class FormationEventItemBODTO {

  idFormationEvent: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  startDate: Date | null = null;
  endDate: Date | null = null;
  organisation: string | null = '';
  nbParticipantSlots: number | null = 0;
  nbParticipantsValidated: number | null = 0;
  nbQueueSlots: number | null = 0;
  nbParticipantsQueued: number | null = 0;
  del: boolean | null = false;
  tags: FormationEventTag[] | null = [];

  constructor(init?:Partial<FormationEventItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventItemBODTO'] = FormationEventItemBODTO;

reverseMapping[FormationEventItemBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventItemBODTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventItemBODTO']  = {
    idFormationEvent: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    organisation: 'java.lang.String',
    nbParticipantSlots: 'java.lang.Integer',
    nbParticipantsValidated: 'java.lang.Integer',
    nbQueueSlots: 'java.lang.Integer',
    nbParticipantsQueued: 'java.lang.Integer',
    del: 'java.lang.Boolean',
    tags: 'java.util.List<fr.hiji.metier.mif.entities.event.FormationEventTag>'
};
