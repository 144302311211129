// fr.hiji.metier.campaign.services.front.EdenRedSurveySpecificServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class EdenRedSurveySpecificService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTagsForSeparation(): RpcRequestBuilder<number[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EdenRedSurveySpecificService.getTagsForSeparation';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  registerTagForUser(idTag: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EdenRedSurveySpecificService.registerTagForUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idTag, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getTagForUser(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EdenRedSurveySpecificService.getTagForUser';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
