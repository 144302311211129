import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { L, UserEditValidators } from '@ic2/ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EmailAvailability } from '../../ic2/entities/EmailAvailability';
import { UserRegisterDTO } from '../../ic2/entities/UserRegisterDTO';
import { RegisterService } from '../../ic2/services/RegisterService';
import { AuthService } from '../../services/auth.service';
import { DataProcessingModalComponent } from '../data-processing-modal/data-processing-modal.component';

@Component({
  selector: 'hiji-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  //https://www.ocpsoft.org/tutorials/regular-expressions/password-regular-expression/
  static passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*"'-_\[\]\(\)])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  formMail: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  formPass: FormGroup = this.fb.group(
    {
      password: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
      confirmPassword: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
    },
    {
      validator: UserEditValidators.checkPasswords('password', 'confirmPassword'),
    }
  );
  formUser: FormGroup = this.fb.group(
    {
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
      confirmPassword: [null, [Validators.required, Validators.pattern(SignupComponent.passwordPattern)]],
    },
    {
      validator: UserEditValidators.checkPasswords('password', 'confirmPassword'),
    }
  );
  readonly STEP_1 = 1;
  readonly STEP_CREATE = 2;
  readonly STEP_PWD = 3;
  step: number = this.STEP_1;
  loading: boolean = false;
  loginError: string;
  data: UserRegisterDTO = null;
  backoffice: boolean = false;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private registerService: RegisterService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private ngbModal: NgbModal
  ) {
    this.backoffice = this.route.snapshot.data['backoffice'] ?? false;
  }

  ngOnInit(): void {}

  getEtabs() {
    return this.data.etablissementNames.join(', ');
  }

  onSubmitEmail() {
    if (!this.formMail.valid) {
      return;
    }
    if (this.loading) return;
    this.loading = true;
    this.registerService.emailAvailable(this.formMail.value.email).subscribe(
      (data) => {
        if (data === EmailAvailability.NOT_AVAILABLE) {
          this.loginError = this.translate.instant("common.signup.L'Email existe déjà");
          this.loading = false;
        } else if (data === EmailAvailability.ACCOUNT_CREATION) {
          this.step = this.STEP_CREATE;
          this.loading = false;
        } else if (data === EmailAvailability.PWD_CREATION) {
          this.registerService.getInfos(this.formMail.value.email).subscribe(
            (d2) => {
              this.loading = false;
              this.data = d2;
              this.step = this.STEP_PWD;
            },
            (err) => {
              L.e(err);
              this.loading = false;
            }
          );
        }
      },
      (err) => {
        this.loading = false;
        L.e(err);
        this.loginError = this.translate.instant("common.signup.Impossible de vérifier si l'email est disponible");
      }
    );
  }

  onSubmitUser() {
    if (!this.formUser.valid) {
      return;
    }
    if (this.loading) return;
    this.loading = true;
    this.registerService
      .register(
        this.formUser.value.firstName,
        this.formUser.value.lastName,
        this.formMail.value.email,
        this.formUser.value.password,
        this.backoffice ? AuthService.BACKOFFICE_ORIGIN : AuthService.FRONTOFFICE_ORIGIN
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.authService.loginWith(data.ub, () => {
            this.router.navigate(['/']);
          });
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
  }

  onSubmitPwd() {
    if (!this.formPass.valid) {
      return;
    }
    if (this.loading) return;
    this.loading = true;
    this.registerService
      .registerPassword(
        this.formMail.value.email,
        this.formPass.value.password,
        this.backoffice ? AuthService.BACKOFFICE_ORIGIN : AuthService.FRONTOFFICE_ORIGIN
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.authService.loginWith(data.ub, () => {
            this.router.navigate(['/']);
          });
        },
        (err) => {
          this.loading = false;
          L.e(err);
        }
      );
  }

  showPopup() {
    this.ngbModal.open(DataProcessingModalComponent, { centered: true });
  }
}
