import { Component, OnInit, TemplateRef } from '@angular/core';
import { Ic2ToastrService } from '../../services/ic2-toastr.service';

@Component({
  selector: 'ic2-toastr-container',
  templateUrl: './ic2-toastr-container.component.html',
  styleUrls: ['./ic2-toastr-container.component.scss'],
  host: {
    '[class.toast-container]': 'true',
    '[class.p-3]': 'true',
  },
})
export class Ic2ToastrContainerComponent implements OnInit {
  constructor(public toastr: Ic2ToastrService) {}

  ngOnInit(): void {}

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
