import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Filter, GenericFilter, MsPipe, MultilingualString, TextassistComponent, string_to_slug } from '@ic2/ic2-lib';
import { RepositoryItemBODTO } from '../../ic2/entities/RepositoryItemBODTO';
import { RepositoryBOService } from '../../ic2/services/RepositoryBOService';

@Component({
  selector: 'hiji-repository-select',
  templateUrl: './repository-select.component.html',
  styleUrls: ['./repository-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RepositorySelectComponent),
      multi: true,
    },
  ],
})
export class RepositorySelectComponent implements ControlValueAccessor, OnInit {
  repositories: RepositoryItemBODTO[] = [];

  @Input()
  placeholder: string = '';
  idRepository: number = 0;
  @Input()
  emptyMeansNull: boolean = false;
  @Input()
  idStartRepository: number = 0;
  @Input()
  clearAfterAdd: boolean = false;
  repositoryStartName: MultilingualString;
  loading: boolean = false;
  loadingRepositoryName: boolean = true;
  clearing: boolean = false;
  @Input()
  disabled: boolean = false;

  @Output()
  repositoryChanged = new EventEmitter<RepositoryItemBODTO>();

  string_to_slug = string_to_slug;

  keyExtractorRepository = (ref: RepositoryItemBODTO) => (ref === null ? (this.emptyMeansNull ? null : 0) : ref.repository.idRepository);
  presenterRepository = (ref) => this.msPipe.transform(ref.repository.name);
  @ViewChild('ta') ta: TextassistComponent<RepositoryItemBODTO>;

  constructor(private repositoryBOService: RepositoryBOService, private msPipe: MsPipe) {}
  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    if (this.idStartRepository != 0 && this.idStartRepository != null) {
      this.repositoryBOService
        .getRepositoryName(this.idStartRepository)
        .defaultOnError()
        .execute((data) => {
          this.repositoryStartName = data;
          this.loadingRepositoryName = false;
        });
    } else {
      this.loadingRepositoryName = false;
    }
  }

  repositoryTextChanged(text: string): void {
    if (text.length === 0) return;

    const filter = new GenericFilter();
    filter.filters = [new Filter('recherche').withStringValues(text)];
    this.repositoryBOService
      .getRepositories(filter)
      .defaultOnError()
      .execute((data) => {
        this.repositories = data.data;
      });
  }

  registerOnChange(fn: number) {
    this.onChange = fn;
  }
  registerOnTouched(fn: number) {
    this.onTouch = fn;
  }

  writeValue(value: number) {
    this.idRepository = this.emptyMeansNull && value === 0 ? null : value;
  }

  setValue(idRepository: number) {
    if (this.clearing) return;
    this.idRepository = idRepository;
    this.repositoryChanged.emit(this.getRepository(idRepository));
    this.change();
    if (this.clearAfterAdd) {
      this.clearing = true;
      this.ta.clear();
      this.repositories = [];
      this.clearing = false;
    }
  }

  change() {
    if ((this.idRepository === null || this.idRepository === 0) && this.clearAfterAdd) return;
    this.onChange(this.idRepository);
    this.onTouch(this.idRepository);
  }

  getRepository(idRepository: number): RepositoryItemBODTO {
    return this.repositories.find((r) => r.repository.idRepository === idRepository);
  }
}
