// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleCertificationDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleCertificationDTO {

  moduleCertificationEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleCertificationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleCertificationDTO'] = ModuleCertificationDTO;

reverseMapping[ModuleCertificationDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleCertificationDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleCertificationDTO']  = {
    moduleCertificationEnabled: 'java.lang.Boolean'
};
