// ENTITY fr.hiji.metier.reseau.dto.FonctionBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Fonction } from './Fonction';
import { UserLiteDTO } from './UserLiteDTO';

export class FonctionBODTO {

  fonction: Fonction | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  users: UserLiteDTO[] | null = [];

  constructor(init?:Partial<FonctionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.dto.FonctionBODTO'] = FonctionBODTO;

reverseMapping[FonctionBODTO.name] = 'fr.hiji.metier.reseau.dto.FonctionBODTO';

fields['fr.hiji.metier.reseau.dto.FonctionBODTO']  = {
    fonction: 'fr.hiji.metier.reseau.entities.Fonction',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    users: 'java.util.List<fr.hiji.metier.user.dto.UserLiteDTO>'
};
