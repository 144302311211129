import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'hiji-smart-pagination',
  templateUrl: './smart-pagination.component.html',
  styleUrls: ['./smart-pagination.component.scss'],
})
export class SmartPaginationComponent {
  @Input() page: number = 1;
  @Input() collectionSize: number;
  @Input() maxSize: number;
  @Input() rotate: boolean = true;
  @Input() loading: boolean = false;
  @Input() nbPossiblePerPage: number[] = [10, 20, 50];
  @Input() pageSize: number;
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  onPageChange(newPage: number): void {
    this.page = newPage;
    this.pageChange.emit(this.page);
  }

  onPageSizeChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const newPageSize = parseInt(selectElement.value, 10);
    this.pageSize = newPageSize;
    this.pageSizeChange.emit(this.pageSize);
  }

  constructor() {}
}
