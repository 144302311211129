// ENTITY fr.hiji.metier.reporting.dto.pdf180.PDFInfoDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ExchangeDetailDTO } from './ExchangeDetailDTO';
import { CampaignPodium } from './CampaignPodium';
import { Organisation } from './Organisation';
import { ServicePercentDTO } from './ServicePercentDTO';
import { Report180ConfigBODTO } from './Report180ConfigBODTO';
import { CritereTauxEchange } from './CritereTauxEchange';

export class PDFInfoDTO {

  client: string | null = '';
  objectif: string | null = '';
  date: Date | null = null;
  minStartDate: Date | null = null;
  report180ConfigDTO: Report180ConfigBODTO | null = null;
  organisation: Organisation | null = null;
  effectif: number | null = 0;
  nombreEchangeSemainePassee: number | null = 0;
  nombreEchangeSemaineMoins2: number | null = 0;
  nombreEchangeMoisEnCours: number | null = 0;
  nombreEchangeMoisPrecedent: number | null = 0;
  nombreEchangeCumulAnnee: number | null = 0;
  nombreObservationCumul: number | null = 0;
  nombreObservationTotal: number | null = 0;
  nombreAutoObservationCumul: number | null = 0;
  nombreAutoObservationTotal: number | null = 0;
  tauxEchangeParService: ServicePercentDTO[] | null = [];
  podiums: CampaignPodium[] | null = [];
  tauxEchangeMensuelParEtablissement: CritereTauxEchange[] | null = [];
  tauxEchangeMensuelParManager: CritereTauxEchange[] | null = [];
  tauxEchangeMensuelParRegion: CritereTauxEchange[] | null = [];
  exchangeDetail: ExchangeDetailDTO[] | null = [];

  constructor(init?:Partial<PDFInfoDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.pdf180.PDFInfoDTO'] = PDFInfoDTO;

reverseMapping[PDFInfoDTO.name] = 'fr.hiji.metier.reporting.dto.pdf180.PDFInfoDTO';

fields['fr.hiji.metier.reporting.dto.pdf180.PDFInfoDTO']  = {
    client: 'java.lang.String',
    objectif: 'java.lang.String',
    date: 'java.time.LocalDate',
    minStartDate: 'java.time.LocalDate',
    report180ConfigDTO: 'fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO',
    organisation: 'fr.hiji.metier.reseau.entities.Organisation',
    effectif: 'java.lang.Integer',
    nombreEchangeSemainePassee: 'java.lang.Integer',
    nombreEchangeSemaineMoins2: 'java.lang.Integer',
    nombreEchangeMoisEnCours: 'java.lang.Integer',
    nombreEchangeMoisPrecedent: 'java.lang.Integer',
    nombreEchangeCumulAnnee: 'java.lang.Integer',
    nombreObservationCumul: 'java.lang.Integer',
    nombreObservationTotal: 'java.lang.Integer',
    nombreAutoObservationCumul: 'java.lang.Integer',
    nombreAutoObservationTotal: 'java.lang.Integer',
    tauxEchangeParService: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.ServicePercentDTO>',
    podiums: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.CampaignPodium>',
    tauxEchangeMensuelParEtablissement: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.CritereTauxEchange>',
    tauxEchangeMensuelParManager: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.CritereTauxEchange>',
    tauxEchangeMensuelParRegion: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.CritereTauxEchange>',
    exchangeDetail: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.ExchangeDetailDTO>'
};
