// DB ENUM fr.hiji.metier.mif.entities.eva.EVAStep generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EVAStep {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly INTRO = new EVAStep(1, "Intro", "INTRO");
  static readonly EXPECTATIONS = new EVAStep(10, "Expectations", "EXPECTATIONS");
  static readonly ACQUISITION = new EVAStep(20, "Acquisition", "ACQUISITION");
  static readonly ACTIONS = new EVAStep(30, "Actions", "ACTIONS");
  static readonly EXPECTATIONS_BUSINESS = new EVAStep(40, "Expectations business", "EXPECTATIONS_BUSINESS");
  static readonly QUIZ_QUALIOPI = new EVAStep(50, "Quiz qualiopi", "QUIZ_QUALIOPI");
  static readonly COMMENT = new EVAStep(60, "Commentaire", "COMMENT");
  static readonly DONE = new EVAStep(100, "Done", "DONE");

  static readonly values = [
    EVAStep.INTRO,
 
    EVAStep.EXPECTATIONS,
 
    EVAStep.ACQUISITION,
 
    EVAStep.ACTIONS,
 
    EVAStep.EXPECTATIONS_BUSINESS,
 
    EVAStep.QUIZ_QUALIOPI,
 
    EVAStep.COMMENT,
 
    EVAStep.DONE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EVAStep.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EVAStep.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.eva.EVAStep'] = EVAStep;
reverseMapping[EVAStep.name] = 'fr.hiji.metier.mif.entities.eva.EVAStep';
