// fr.hiji.metier.campaign.services.front.TeamMemberServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { TeamMemberDTO } from '../entities/TeamMemberDTO';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getTeam(): RpcRequestBuilder<TeamMemberDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamMemberService.getTeam';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getTeamMember(idUser: number): RpcRequestBuilder<TeamMemberDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'TeamMemberService.getTeamMember';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.front.TeamMemberDTO');
  }

}
