// ENTITY fr.hiji.metier.certif.dto.front.modality.quiz.QuestionQCMAnswerDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class QuestionQCMAnswerDTO {

  numAnswer: number | null = 0;
  answer: MultilingualString | null = new MultilingualString();
  answerImage: MultilingualString | null = new MultilingualString();
  order: number | null = 0;

  constructor(init?:Partial<QuestionQCMAnswerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.quiz.QuestionQCMAnswerDTO'] = QuestionQCMAnswerDTO;

reverseMapping[QuestionQCMAnswerDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.quiz.QuestionQCMAnswerDTO';

fields['fr.hiji.metier.certif.dto.front.modality.quiz.QuestionQCMAnswerDTO']  = {
    numAnswer: 'java.lang.Integer',
    answer: 'com.ic2.sc.MultilingualString',
    answerImage: 'com.ic2.sc.MultilingualString',
    order: 'java.lang.Integer'
};
