// ENTITY com.ic2.services.dto.UserImageToken generated by ic2 3.0.0-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FileToken } from './FileToken';

export class UserImageToken {

  idUser: number | null = 0;
  token: FileToken | null = null;

  constructor(init?:Partial<UserImageToken>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['com.ic2.services.dto.UserImageToken'] = UserImageToken;

reverseMapping[UserImageToken.name] = 'com.ic2.services.dto.UserImageToken';

fields['com.ic2.services.dto.UserImageToken']  = {
    idUser: 'java.lang.Integer',
    token: 'com.ic2.rpc.tokens.FileToken'
};
