// ENTITY fr.hiji.metier.reseau.dto.FonctionItemBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Fonction } from './Fonction';

export class FonctionItemBODTO {

  countUsers: number | null = 0;
  fonction: Fonction | null = null;

  constructor(init?:Partial<FonctionItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.dto.FonctionItemBODTO'] = FonctionItemBODTO;

reverseMapping[FonctionItemBODTO.name] = 'fr.hiji.metier.reseau.dto.FonctionItemBODTO';

fields['fr.hiji.metier.reseau.dto.FonctionItemBODTO']  = {
    countUsers: 'java.lang.Integer',
    fonction: 'fr.hiji.metier.reseau.entities.Fonction'
};
