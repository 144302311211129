import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { ModalService } from '../components/modal/modal.service';
import { AbstractViewAddEdit } from '../tools/view-add-edit.base';

@Injectable({
  providedIn: 'root',
})
export class DeactivateGuard {
  constructor(
    private translate: TranslateService,
    private modalService: ModalService
  ) {}
  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      component instanceof AbstractViewAddEdit &&
      (component.add || component.edit) &&
      component.ngForm !== undefined &&
      component.ngForm.dirty &&
      !component.addOrDelBtnClicked
    ) {
      return new Observable((subscriber) => {
        this.modalService.openModal(
          'confirm',
          this.translate.instant('common.global.Êtes vous sûr de quitter ?'),
          () => {
            subscriber.next(true);
            subscriber.complete();
          },
          () => {
            subscriber.next(false);
            subscriber.complete();
          }
        );
      });
    }
    if (!(component instanceof AbstractViewAddEdit)) {
      L.e('DeactivateGuard ', component, "N'est pas une instance de AbstractViewAddEdit , surement a enlever un soucis dans les routes (canDeactivate)");
    }

    return true;
  }
}
