import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ic2Module } from '@ic2/ic2-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserTextassistComponent } from './user-textassist.component';

@NgModule({
  declarations: [UserTextassistComponent],
  imports: [Ic2Module, NgbModule, CommonModule, FormsModule, ReactiveFormsModule, NgbModule],
  exports: [UserTextassistComponent],
})
export class UserTextassistModule {}
