export const LOCALE_LOAD_MAPPING = {
  'en-US': 'en',
  'en-AUS_NZ': 'en',
  'pt-BR': 'pt-PT',
  'es-ES': 'es',
  'zh-SG': 'zh-Hans-SG',
  'zh-CN': 'zh',
  'zh-HK': 'zh-Hans-HK',
  'zh-TW': 'zh',
  'de-CH': 'de',
};
