import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvService, L, RpcError } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { HijiRight } from '../../ic2/entities/HijiRight';
import { SSOConfigDTO } from '../../ic2/entities/SSOConfigDTO';
import { SSOService } from '../../ic2/services/SSOService';
import { AuthService } from '../../services/auth.service';
import { ResetPasswordService } from '../../services/reset-password.service';
import { Config } from '../../tools/Config';

@Component({
  selector: 'hiji-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup = this.fb.group({
    login: [null, [Validators.required]],
    password: [null, [Validators.required, Validators.minLength(6)]],
  });
  error: string = null;
  loadingSSOConfigs: boolean = true;
  loading: boolean = false;
  backoffice: boolean = false;
  localAccountLoginEnabled: boolean = true;
  signupEnabled: boolean = true;
  ssoConfigs: SSOConfigDTO[] = [];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private resetPasswordService: ResetPasswordService,
    public config: EnvService<Config>,
    private ssoService: SSOService
  ) {
    this.localAccountLoginEnabled = config.configuration.localAccountLoginEnabled;
    this.signupEnabled = config.configuration.signupEnabled;
    this.backoffice = this.route.snapshot.data['backoffice'] ?? false;
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.router.navigate(['/']);
      else this.authService.logout();
    });
    this.form.valueChanges.subscribe((formValues) => {
      this.resetPasswordService.email = formValues.login;
    });
    this.ssoService.getSSOConfigList().execute((data) => {
      this.ssoConfigs = data;
      this.loadingSSOConfigs = false;
    });
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    if (this.loading) return;
    this.loading = true;
    this.authService
      .login(this.form.value.login, this.form.value.password, this.backoffice ? AuthService.BACKOFFICE_ORIGIN : AuthService.FRONTOFFICE_ORIGIN)
      .subscribe(
        (data) => {
          this.loading = false;
          if (this.backoffice) {
            const loggedIn = data.userBundle && AuthService.hasAccessToBackoffice(data.userBundle);
            if (!loggedIn) {
              data.permitLogin = false;
              this.error = this.translate.instant("common.login.error.Ce compte n'a pas d'accès au backoffice");
              return;
            }
          }
          if (data.redirectUrl === null) data.redirectUrl = '/';
          if (this.backoffice && AuthService.has(data.userBundle, HijiRight.CONSULTATION_STATS)) data.redirectUrl = '/campaign';
          if (this.backoffice && AuthService.has(data.userBundle, HijiRight.TRADUCTEUR)) data.redirectUrl = '/configuration/translation';
        },
        (err: RpcError) => {
          this.loading = false;
          if (err.isBusinessError()) {
            this.error = this.translate.instant('common.login.Echec de la connexion');
          } else if (err.code === -33000) {
            this.error = this.translate.instant('common.login.Maintenance en cours');
          } else {
            this.error = this.translate.instant('common.login.Une erreur est survenue lors de la connexion');
            L.e(err);
          }
        }
      );
  }
}
