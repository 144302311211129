// fr.hiji.metier.campaign.services.back.CampaignPDIBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { CampaignPDIUserFormation } from '../entities/CampaignPDIUserFormation';
import { CampaignPDIUserFormationResultBODTO } from '../entities/CampaignPDIUserFormationResultBODTO';

@Injectable({
  providedIn: 'root',
})
export class CampaignPDIBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getResult(idCampaign: number, idUser: number): RpcRequestBuilder<CampaignPDIUserFormationResultBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignPDIBOService.getResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserFormationResultBODTO');
  }

  resetAutoObsToDraft(idCampaign: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignPDIBOService.resetAutoObsToDraft';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deployCampaignPDI(idCampaign: number, idUsers: number[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignPDIBOService.deployCampaignPDI';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUsers, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  resetManagerObsToDraft(idCampaign: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignPDIBOService.resetManagerObsToDraft';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  saveFormations(idCampaign: number, idUser: number, formations: CampaignPDIUserFormation[], sendMail: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CampaignPDIBOService.saveFormations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(formations, 'java.util.List<fr.hiji.metier.campaign.entities.pdi.CampaignPDIUserFormation>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(sendMail, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
