import { Component, Input } from '@angular/core';
import { MultilingualString } from '@ic2/ic2-lib';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hiji-quiz-qcm-details-modal',
  templateUrl: './quiz-qcm-details-modal.component.html',
  styleUrl: './quiz-qcm-details-modal.component.scss',
})
export class QuizQcmDetailsModalComponent {
  @Input()
  data: Partial<{
    distribution: number | null;
    nbQuestionAnswered: number | null;
    idQuestion: number | null;
    question: MultilingualString | null;
    questionImage: MultilingualString | null;
    answer: MultilingualString | null;
    answerImage: MultilingualString | null;
    isGoodAnswer: boolean | null;
  }>[] = [];

  constructor(public modal: NgbActiveModal) {}
}
