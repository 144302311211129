import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDatepickerI18nCustom extends NgbDatepickerI18n {
  constructor(@Inject(LOCALE_ID) private locale: any) {
    super();
  }
  getWeekdayLabel(weekday: number, width: Exclude<Intl.DateTimeFormatOptions['weekday'], undefined> = 'narrow'): string {
    const weekdaysStartingOnSunday = Array.from(Array(7).keys()).map((day) =>
      Intl.DateTimeFormat(this.locale.toString(), { weekday: width }).format(new Date(Date.UTC(2021, 5, day - 1)))
    );
    const weekdays = weekdaysStartingOnSunday.map((day, index) => weekdaysStartingOnSunday[(index + 1) % 7]);
    return weekdays[weekday - 1] || '';
  }

  getMonthShortName(month: number): string {
    return Intl.DateTimeFormat(this.locale.toString(), { month: 'short' }).format(new Date(2000, month - 1)) || '';
  }

  getMonthFullName(month: number): string {
    return Intl.DateTimeFormat(this.locale.toString(), { month: 'long' }).format(new Date(2000, month - 1)) || '';
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, 'fullDate', this.locale.toString());
  }
}
