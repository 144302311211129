// ENTITY fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CertificationExam } from './CertificationExam';
import { ModalityDTO } from './ModalityDTO';
import { ObservationObservableGesturesDTO } from './ObservationObservableGesturesDTO';
import { Criteria } from './Criteria';

export class ModalityRoleplayDTO {

  idRepository: number | null = 0;
  modality: ModalityDTO | null = null;
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDescription: MultilingualString | null = new MultilingualString();
  exam: CertificationExam | null = null;
  observableGestures: ObservationObservableGesturesDTO[] | null = [];
  criterias: Criteria[] | null = [];
  startAnsweringDate: Date | null = null;
  idModalityRoleplayAnswer: number | null = 0;
  draft: boolean | null = false;
  hideSkill: boolean | null = false;
  forceAnswerAllQuestions: boolean | null = false;

  constructor(init?:Partial<ModalityRoleplayDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayDTO'] = ModalityRoleplayDTO;

reverseMapping[ModalityRoleplayDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayDTO';

fields['fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayDTO']  = {
    idRepository: 'java.lang.Integer',
    modality: 'fr.hiji.metier.certif.dto.front.modality.ModalityDTO',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDescription: 'com.ic2.sc.MultilingualString',
    exam: 'fr.hiji.metier.certif.entities.session.CertificationExam',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservationObservableGesturesDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    startAnsweringDate: 'java.time.Instant',
    idModalityRoleplayAnswer: 'java.lang.Integer',
    draft: 'java.lang.Boolean',
    hideSkill: 'java.lang.Boolean',
    forceAnswerAllQuestions: 'java.lang.Boolean'
};
