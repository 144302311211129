<div class="row text-end mt-2 my-fs-1">
  <div class="col-12">
    {{
      'common.created-updated-by.Créé par : \{\{userCreator\}\} le \{\{creationDate\}\}'
        | translate: { userCreator: userCreator(), creationDate: creationDate() | localizedDate: 'short' }
    }}
  </div>
  <div class="col-12">
    <ng-container *ngIf="updateDate() === null">
      {{ 'common.created-updated-by.Dernière modification : Aucune modification' | translate }}
    </ng-container>
    <ng-container *ngIf="updateDate() !== null">
      {{
        'common.created-updated-by.Dernière modification par : \{\{userUpdater\}\} le \{\{updateDate\}\}'
          | translate: { userUpdater: userUpdater(), updateDate: updateDate() | localizedDate: 'short' }
      }}
    </ng-container>
  </div>
</div>
