import { Component, Input, OnInit } from '@angular/core';
import { CriteriaType } from '../../ic2/entities/CriteriaType';

@Component({
  selector: 'hiji-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit {
  @Input() positive: number = 0;
  @Input() neutral: number = 0;
  @Input() negative: number = 0;
  @Input() verynegative: number = 0;
  @Input() criteriatype: any;
  @Input() colors: any;
  CriteriaType = CriteriaType;

  constructor() {}

  ngOnInit(): void {}
}
