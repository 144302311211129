import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EnvService, L } from '@ic2/ic2-lib';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StaticPageService } from '../../ic2/services/StaticPageService';
import { Config } from '../../tools/Config';

@Component({
  selector: 'hiji-data-processing-modal',
  templateUrl: './data-processing-modal.component.html',
  styleUrls: ['./data-processing-modal.component.scss'],
})
export class DataProcessingModalComponent implements OnInit {
  html: SafeHtml | string;
  loading: boolean = true;
  constructor(
    public modal: NgbActiveModal,
    private staticPageService: StaticPageService,
    private translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private env: EnvService<Config>
  ) {}

  ngOnInit(): void {
    this.staticPageService.getPage('data-processing').subscribe(
      (data) => {
        this.loading = false;
        if (!(this.translate.currentLang in data.html.obj)) {
          this.html = this.domSanitizer.bypassSecurityTrustHtml(data.html.obj[Object.keys(data.html.obj)[0]]); //default to first one
        } else this.html = this.domSanitizer.bypassSecurityTrustHtml(data.html.obj[this.translate.currentLang]);
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError())
          this.html = this.domSanitizer.bypassSecurityTrustHtml(
            'Désolé, cette page n\'a pas encoré été rédigée. Merci de la rédiger dans le <a href="' +
              this.env.configuration.boUrl +
              '/static-page">backoffice</a>'
          );
        else L.e(err);
      }
    );
  }
}
