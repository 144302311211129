<div class="card bg-light-30p rounded-2 shadow-1 h-100">
  <div class="row g-0 justify-content-center align-items-center h-100">
    <div class="col-auto pt-2 px-4 text-center">
      <i class="fa-light fa-2x" [ngClass]="icon"></i>
    </div>
    <div class="col py-3 px-1">
      <h6>{{ title }}</h6>
      <div class="my-fs-2 text-center text-lg-start" *ngIf="detail">{{ detail }}</div>
    </div>
    <div class="col-auto my-fs-5 px-3" *ngIf="value">{{ value }}</div>
  </div>
  <div class="row g-0" *ngIf="footer">
    <div class="col text-muted text-end my-fs-1 px-1">{{ footer }}</div>
  </div>
</div>
