// ENTITY fr.hiji.metier.user.dto.UserRegisterDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserRegisterDTO {

  firstName: string | null = '';
  lastName: string | null = '';
  fonctionName: string | null = '';
  etablissementNames: string[] | null = [];

  constructor(init?:Partial<UserRegisterDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.UserRegisterDTO'] = UserRegisterDTO;

reverseMapping[UserRegisterDTO.name] = 'fr.hiji.metier.user.dto.UserRegisterDTO';

fields['fr.hiji.metier.user.dto.UserRegisterDTO']  = {
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonctionName: 'java.lang.String',
    etablissementNames: 'java.util.List<java.lang.String>'
};
