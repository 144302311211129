// fr.hiji.metier.mif.services.front.FormationEventAcquisitionServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationEventAcquisitionDTO } from '../entities/FormationEventAcquisitionDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationEventAcquisitionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  saveAcquisitionObservations(dto: FormationEventAcquisitionDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventAcquisitionService.saveAcquisitionObservations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.event.FormationEventAcquisitionDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getAcquisition(idFormationEvent: number): RpcRequestBuilder<FormationEventAcquisitionDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventAcquisitionService.getAcquisition';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationEvent, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.event.FormationEventAcquisitionDTO');
  }

}
