// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventParticipantImportBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class FormationEventParticipantImportBODTO {

  users: UserFuncAvatarDTO[] | null = [];
  emailsErrors: string[] | null = [];

  constructor(init?:Partial<FormationEventParticipantImportBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventParticipantImportBODTO'] = FormationEventParticipantImportBODTO;

reverseMapping[FormationEventParticipantImportBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventParticipantImportBODTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventParticipantImportBODTO']  = {
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    emailsErrors: 'java.util.List<java.lang.String>'
};
