import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface HijiHttpTranslateLoaderOptions {
  prefix?: string;
  suffix?: string;
  fallbackLanguage?: string;
}
export class HijiHttpTranslateLoader implements TranslateLoader {
  options: HijiHttpTranslateLoaderOptions;
  constructor(private httpClient: HttpClient, options: HijiHttpTranslateLoaderOptions = { prefix: '/assets/i18n/', suffix: '.json', fallbackLanguage: 'en' }) {
    this.options = { ...{ prefix: '/assets/i18n/', suffix: '.json', fallbackLanguage: 'en' }, ...options };
  }

  getTranslation(lang: string): Observable<File> {
    const def = this.options.prefix + lang + this.options.suffix;
    const fallback = this.options.prefix + this.options.fallbackLanguage + this.options.suffix;
    return this.httpClient.get<File>(def).pipe(
      catchError((): Observable<File> => {
        return this.httpClient.get<File>(fallback).pipe(
          catchError((err, caught) => {
            console.log('ERR loading translation', err);
            return new Observable<File>((observer) => {
              observer.next(new File([], 'empty'));
              observer.complete();
            });
          })
        );
      })
    );
  }
}
