import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { ResetPwdService } from '../../../ic2/services/ResetPwdService';
import { AuthService } from '../../../services/auth.service';
import { ResetPasswordService } from '../../../services/reset-password.service';

@Component({
  selector: 'hiji-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });
  loading: boolean = false;
  error: boolean = false;
  email: string = '';

  constructor(
    private fb: FormBuilder,
    private resetPwdService: ResetPwdService,
    private router: Router,
    private resetPasswordService: ResetPasswordService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.logout();
    if (this.resetPasswordService.email != null) {
      this.email = this.resetPasswordService.email;
      this.form.patchValue({ email: this.email });
    }
  }

  submit() {
    this.error = false;
    if (!this.form.valid) {
      return;
    }
    this.loading = true;
    this.resetPwdService.forgotPassword(this.form.value.email).subscribe(
      (data) => {
        this.loading = false;
        this.router.navigate(['/reset-password/confirm']);
      },
      (err) => {
        this.loading = false;
        if (err.isBusinessError()) {
          this.error = true;
        } else L.e(err);
      }
    );
  }
}
