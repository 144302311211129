import { Injectable } from '@angular/core';
import { RpcRequestBuilder } from '@ic2/ic2-lib';
import { ModalityQuizDTO } from '../ic2/entities/ModalityQuizDTO';
import { QuizMIFDTO } from '../ic2/entities/QuizMIFDTO';

@Injectable({
  providedIn: 'root',
})
export class AutoSaveHelperService {
  private autoSaveMap = new Map<number, boolean>();
  private previouslyAnsweredQuestionId: number = -1;
  private loadingAutoSave: boolean = false;
  private needToAutoSave: boolean = false;
  private needToSave: boolean = false;
  private saveMethod: (dto: QuizMIFDTO | ModalityQuizDTO) => RpcRequestBuilder<QuizMIFDTO | ModalityQuizDTO> = null;

  setSaveMethod(saveMethod: (dto: QuizMIFDTO | ModalityQuizDTO) => RpcRequestBuilder<QuizMIFDTO | ModalityQuizDTO>) {
    this.saveMethod = saveMethod;
  }

  setAutoSaveStatus(questionId: number, status: boolean) {
    this.autoSaveMap.set(questionId, status);
  }

  getAutoSaveStatus(questionId: number): boolean {
    return this.autoSaveMap.get(questionId) || false;
  }

  getLoadingAutoSave(): boolean {
    return this.loadingAutoSave;
  }

  autoSaveObservation(data: QuizMIFDTO | ModalityQuizDTO, questionId: number) {
    if (this.loadingAutoSave) {
      this.needToAutoSave = true;
      return;
    }

    this.setAutoSaveStatus(questionId, false);

    if (this.needsToAutoSave(questionId)) {
      if (this.saveMethod === null) throw new Error('not implemented');

      this.loadingAutoSave = true;
      data.draft = true;

      this.saveMethod(data)
        .defaultOnErrorAnd((err) => {
          this.setAutoSaveStatus(this.previouslyAnsweredQuestionId, false);
          this.loadingAutoSave = false;
        })
        .execute(() => {
          this.setAutoSaveStatus(this.previouslyAnsweredQuestionId, true);
          this.previouslyAnsweredQuestionId = questionId;
          this.loadingAutoSave = false;
          if (this.needToAutoSave) {
            this.needToAutoSave = false;
            this.autoSaveObservation(data, questionId);
          } else if (this.needToSave) {
            this.needToSave = false;
            this.saveObservation(data, false);
          }
        });
    } else {
      this.previouslyAnsweredQuestionId = questionId;
    }
  }

  saveObservation(data: QuizMIFDTO | ModalityQuizDTO, draft: boolean) {
    return new Promise((resolve, reject) => {
      if (this.saveMethod === null) throw new Error('not implemented');

      this.needToSave = true;
      data.draft = draft;

      return this.saveMethod(data)
        .defaultOnErrorAnd((err) => {
          this.needToSave = false;
          return reject(err);
        })
        .execute((answer) => {
          this.needToSave = false;
          return resolve(answer);
        });
    });
  }

  private needsToAutoSave(questionId: number): boolean {
    return this.previouslyAnsweredQuestionId !== -1 && this.previouslyAnsweredQuestionId !== questionId;
  }
}
