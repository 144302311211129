// ENTITY fr.hiji.metier.referential.dto.back.QuizQuestionBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { QuizQuestion } from './QuizQuestion';
import { QuestionQCMBODTO } from './QuestionQCMBODTO';
import { Question } from './Question';

export class QuizQuestionBODTO {

  quizQuestion: QuizQuestion | null = null;
  question: Question | null = null;
  idsTags: number[] | null = [];
  qcm: QuestionQCMBODTO | null = null;
  hasAnswers: boolean | null = false;

  constructor(init?:Partial<QuizQuestionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.back.QuizQuestionBODTO'] = QuizQuestionBODTO;

reverseMapping[QuizQuestionBODTO.name] = 'fr.hiji.metier.referential.dto.back.QuizQuestionBODTO';

fields['fr.hiji.metier.referential.dto.back.QuizQuestionBODTO']  = {
    quizQuestion: 'fr.hiji.metier.referential.entities.quiz.QuizQuestion',
    question: 'fr.hiji.metier.referential.entities.quiz.Question',
    idsTags: 'java.util.List<java.lang.Integer>',
    qcm: 'fr.hiji.metier.referential.dto.back.QuestionQCMBODTO',
    hasAnswers: 'java.lang.Boolean'
};
