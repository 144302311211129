// ENTITY fr.hiji.metier.certif.dto.front.CertificationWithSessionsDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CertificationSession } from './CertificationSession';

export class CertificationWithSessionsDTO {

  idCertification: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  certified: boolean | null = false;
  certificationSessions: CertificationSession[] | null = [];

  constructor(init?:Partial<CertificationWithSessionsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.CertificationWithSessionsDTO'] = CertificationWithSessionsDTO;

reverseMapping[CertificationWithSessionsDTO.name] = 'fr.hiji.metier.certif.dto.front.CertificationWithSessionsDTO';

fields['fr.hiji.metier.certif.dto.front.CertificationWithSessionsDTO']  = {
    idCertification: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    certified: 'java.lang.Boolean',
    certificationSessions: 'java.util.List<fr.hiji.metier.certif.entities.session.CertificationSession>'
};
