// fr.hiji.metier.conf.services.back.submodules.KPIModuleCfgBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class KPIModuleCfgBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getImportFilename(): RpcRequestBuilder<string[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'KPIModuleCfgBOService.getImportFilename';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  deleteKpi(importFilename: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'KPIModuleCfgBOService.deleteKpi';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(importFilename, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
