// ENTITY fr.hiji.metier.mif.dto.front.manager.LayoutManagerDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class LayoutManagerDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  formationSessionName: string | null = '';
  formationImageExtension: string | null = '';
  minDate: Date | null = null;
  maxDate: Date | null = null;
  user: UserFuncAvatarDTO | null = null;

  constructor(init?:Partial<LayoutManagerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.manager.LayoutManagerDTO'] = LayoutManagerDTO;

reverseMapping[LayoutManagerDTO.name] = 'fr.hiji.metier.mif.dto.front.manager.LayoutManagerDTO';

fields['fr.hiji.metier.mif.dto.front.manager.LayoutManagerDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    formationSessionName: 'java.lang.String',
    formationImageExtension: 'java.lang.String',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO'
};
