// ENTITY fr.hiji.metier.fbs.dto.back.FeedbackCategoryStatBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FeedbackCategoryStatBODTO {

  idFeedbackCategory: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  icon: string | null = '';
  nb: number | null = 0;

  constructor(init?:Partial<FeedbackCategoryStatBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.dto.back.FeedbackCategoryStatBODTO'] = FeedbackCategoryStatBODTO;

reverseMapping[FeedbackCategoryStatBODTO.name] = 'fr.hiji.metier.fbs.dto.back.FeedbackCategoryStatBODTO';

fields['fr.hiji.metier.fbs.dto.back.FeedbackCategoryStatBODTO']  = {
    idFeedbackCategory: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    icon: 'java.lang.String',
    nb: 'java.lang.Integer'
};
