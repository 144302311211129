// ENTITY fr.hiji.metier.reporting.dto.mif.global.QuizDataStatsMIFDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { FormationQuizQuestionStatsBODTO } from './FormationQuizQuestionStatsBODTO';
import { FormationQuizQuestionOuverteStatsBODTO } from './FormationQuizQuestionOuverteStatsBODTO';
import { FormationSkillStatsBODTO } from './FormationSkillStatsBODTO';

export class QuizDataStatsMIFDTO {

  quiz: FormationQuizQuestionStatsBODTO[] | null = [];
  questionOuverte: FormationQuizQuestionOuverteStatsBODTO[] | null = [];
  skills: FormationSkillStatsBODTO[] | null = [];
  nameQuiz: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<QuizDataStatsMIFDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.mif.global.QuizDataStatsMIFDTO'] = QuizDataStatsMIFDTO;

reverseMapping[QuizDataStatsMIFDTO.name] = 'fr.hiji.metier.reporting.dto.mif.global.QuizDataStatsMIFDTO';

fields['fr.hiji.metier.reporting.dto.mif.global.QuizDataStatsMIFDTO']  = {
    quiz: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionStatsBODTO>',
    questionOuverte: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionOuverteStatsBODTO>',
    skills: 'java.util.List<fr.hiji.metier.mif.dto.back.stats.FormationSkillStatsBODTO>',
    nameQuiz: 'com.ic2.sc.MultilingualString'
};
