// ENTITY fr.hiji.metier.campaign.dto.front._360.InquiryDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { InquiryTargetDTO } from './InquiryTargetDTO';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { InquiryActionPlan } from './InquiryActionPlan';
import { InquiryTargetMessages } from './InquiryTargetMessages';
import { Campaign360SkillTarget } from './Campaign360SkillTarget';
import { InquirySkillStatDTO } from './InquirySkillStatDTO';
import { InquiryObservableGestureDTO } from './InquiryObservableGestureDTO';
import { Criteria } from './Criteria';
import { Inquiry } from './Inquiry';
import { InquiryTargetOOGDTO } from './InquiryTargetOOGDTO';

export class InquiryDTO {

  inquiry: Inquiry | null = null;
  ogs: InquiryObservableGestureDTO[] | null = [];
  criterias: Criteria[] | null = [];
  targets: InquiryTargetDTO[] | null = [];
  answers: InquiryTargetOOGDTO[] | null = [];
  messages: InquiryTargetMessages[] | null = [];
  canReportAnsweredCollaborator: boolean | null = false;
  canReportAnsweredPair: boolean | null = false;
  canReportAnsweredManager: boolean | null = false;
  canReportAnsweredExternal: boolean | null = false;
  isAlreadyAutoObserved: boolean | null = false;
  autoObsDraft: boolean | null = false;
  nbAnswers: number | null = 0;
  inquiryUser: UserFuncAvatarDTO | null = null;
  skillStats: InquirySkillStatDTO[] | null = [];
  actionPlans: InquiryActionPlan[] | null = [];
  skillTargets: Campaign360SkillTarget[] | null = [];
  respectAnonView: boolean | null = false;
  isAssessorViewving: boolean | null = false;
  isAdminViewving: boolean | null = false;
  disablePDFGeneration: boolean | null = false;
  responsibleUser: UserFuncAvatarDTO | null = null;

  constructor(init?:Partial<InquiryDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front._360.InquiryDTO'] = InquiryDTO;

reverseMapping[InquiryDTO.name] = 'fr.hiji.metier.campaign.dto.front._360.InquiryDTO';

fields['fr.hiji.metier.campaign.dto.front._360.InquiryDTO']  = {
    inquiry: 'fr.hiji.metier.campaign.entities._360.Inquiry',
    ogs: 'java.util.List<fr.hiji.metier.campaign.dto.front._360.InquiryObservableGestureDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    targets: 'java.util.List<fr.hiji.metier.campaign.dto.front._360.InquiryTargetDTO>',
    answers: 'java.util.List<fr.hiji.metier.campaign.dto.front._360.InquiryTargetOOGDTO>',
    messages: 'java.util.List<fr.hiji.metier.campaign.dto.front._360.InquiryTargetMessages>',
    canReportAnsweredCollaborator: 'java.lang.Boolean',
    canReportAnsweredPair: 'java.lang.Boolean',
    canReportAnsweredManager: 'java.lang.Boolean',
    canReportAnsweredExternal: 'java.lang.Boolean',
    isAlreadyAutoObserved: 'java.lang.Boolean',
    autoObsDraft: 'java.lang.Boolean',
    nbAnswers: 'java.lang.Integer',
    inquiryUser: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    skillStats: 'java.util.List<fr.hiji.metier.campaign.dto.front._360.InquirySkillStatDTO>',
    actionPlans: 'java.util.List<fr.hiji.metier.campaign.entities._360.InquiryActionPlan>',
    skillTargets: 'java.util.List<fr.hiji.metier.campaign.entities._360.Campaign360SkillTarget>',
    respectAnonView: 'java.lang.Boolean',
    isAssessorViewving: 'java.lang.Boolean',
    isAdminViewving: 'java.lang.Boolean',
    disablePDFGeneration: 'java.lang.Boolean',
    responsibleUser: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO'
};
