// ENTITY fr.hiji.metier.ema.entities.observation.EMAObservationObservableGestures generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAObservationObservableGestures {

  idEMAObservation: number | null = 0;
  idObservableGesture: number | null = null;
  idEMAUserOGC: number | null = null;
  value: number | null = 0;
  observed: boolean | null = false;
  answerDate: Date | null = null;
  text: string | null = '';

  constructor(init?:Partial<EMAObservationObservableGestures>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.observation.EMAObservationObservableGestures'] = EMAObservationObservableGestures;

reverseMapping[EMAObservationObservableGestures.name] = 'fr.hiji.metier.ema.entities.observation.EMAObservationObservableGestures';

fields['fr.hiji.metier.ema.entities.observation.EMAObservationObservableGestures']  = {
    idEMAObservation: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    idEMAUserOGC: 'java.lang.Integer',
    value: 'java.lang.Integer',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    text: 'java.lang.String'
};
