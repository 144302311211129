import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hiji-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  type: 'confirm' | 'warning';
  message: string;
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
  public confirm(): void {
    this.modal.close(true);
  }

  public cancel(): void {
    this.modal.dismiss('cancel');
  }
}
