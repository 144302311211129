// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventDeployDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventObservationType } from './FormationEventObservationType';

export class FormationEventDeployDTO {

  idFormationEvent: number | null = 0;
  userDeployerFullName: string | null = '';
  deployDate: Date | null = null;
  relance: boolean | null = false;
  withMail: boolean | null = false;
  observationType: FormationEventObservationType | null = null;
  nbNbUserDeployed: number | null = 0;

  constructor(init?:Partial<FormationEventDeployDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventDeployDTO'] = FormationEventDeployDTO;

reverseMapping[FormationEventDeployDTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventDeployDTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventDeployDTO']  = {
    idFormationEvent: 'java.lang.Integer',
    userDeployerFullName: 'java.lang.String',
    deployDate: 'java.time.Instant',
    relance: 'java.lang.Boolean',
    withMail: 'java.lang.Boolean',
    observationType: 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservationType',
    nbNbUserDeployed: 'java.lang.Integer'
};
