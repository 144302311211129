// ENTITY fr.hiji.metier.certif.dto.front.JoinResultDTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class JoinResultDTO {

  certifName: MultilingualString | null = new MultilingualString();
  sessionName: string | null = '';
  campaignName: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<JoinResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.JoinResultDTO'] = JoinResultDTO;

reverseMapping[JoinResultDTO.name] = 'fr.hiji.metier.certif.dto.front.JoinResultDTO';

fields['fr.hiji.metier.certif.dto.front.JoinResultDTO']  = {
    certifName: 'com.ic2.sc.MultilingualString',
    sessionName: 'java.lang.String',
    campaignName: 'com.ic2.sc.MultilingualString'
};
