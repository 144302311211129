// ENTITY fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamOG generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityInterviewExamOG {

  idExam: number | null = 0;
  idObservableGesture: number | null = 0;
  value: number | null = 0;
  observed: boolean | null = false;
  answerDate: Date | null = null;
  text: string | null = '';

  constructor(init?:Partial<ModalityInterviewExamOG>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamOG'] = ModalityInterviewExamOG;

reverseMapping[ModalityInterviewExamOG.name] = 'fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamOG';

fields['fr.hiji.metier.certif.entities.modality.interview.answer.ModalityInterviewExamOG']  = {
    idExam: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    value: 'java.lang.Integer',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    text: 'java.lang.String'
};
