// ENTITY fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CampaignPDIUser } from './CampaignPDIUser';

export class CampaignPDIUserBODTO {

  firstName: string | null = '';
  lastName: string | null = '';
  fonction: string | null = '';
  avatarExtension: string | null = '';
  userDel: boolean | null = false;
  answerDate: Date | null = null;
  draft: boolean | null = false;
  managerAnswerDate: Date | null = null;
  managerDraft: boolean | null = false;
  user: CampaignPDIUser | null = null;

  constructor(init?:Partial<CampaignPDIUserBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserBODTO'] = CampaignPDIUserBODTO;

reverseMapping[CampaignPDIUserBODTO.name] = 'fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserBODTO';

fields['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIUserBODTO']  = {
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonction: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    userDel: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    draft: 'java.lang.Boolean',
    managerAnswerDate: 'java.time.Instant',
    managerDraft: 'java.lang.Boolean',
    user: 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIUser'
};
