export const EmailModuleMapping = {
  _1_360_LANCEMENT_CAMPAGNE: '360',
  _2_360_LANCEMENT_INQUIRY: '360',
  _3_360_RELANCE_INQUIRY_NOT_DEPLOYED: '360',
  _4_360_RELANCE_INQUIRY_NOT_AUTOOBS: '360',
  _5_360_RELANCE_INQUIRY_TARGETS_NO_ANSWER: '360',
  _6_360_INQUIRY_TARGET_ANSWER: '360',
  _7_360_INQUIRY_END_NOT_DEPLOYED_ALERT: '360',
  _8_360_INQUIRY_END_TARGET_NO_ANSWER_ALERT: '360',
  _9_360_INQUIRY_END: '360',
  _10_RESULT_AVAILABLE: 'Restitution',
  _11_360_INQUIRY_TARGET_DEPLOYED: '360',
  _12_NOTIF_FEEDBACK: 'Feedback spontané',
  _13_SEND_EMAIL_RECAP: 'Feedback spontané',
  _14_SEND_EMAIL_FEEDBACK_REMINDER: 'Feedback spontané',
  _15_ACCOUNT_CREATED: 'Global',
  _16_RESET_PWD: 'Global',
  _17_ENVOI_RAPPORT_MENSUEL: '180',
  _18_ENVOI_RAPPORT_HEBDO: '180',
  _19_MAIL_AUTH: 'Global',
  _20_EXCHANGE_REMINDER_NEVER: '180',
  _21_EXCHANGE_REMINDER_NOT_ANYMORE: '180',
  _22_EXCHANGE_REMINDER_1_49: '180',
  _23_EXCHANGE_REMINDER_50_99: '180',
  _24_EXCHANGE_REMINDER_100: '180',
  _25_COLLAB_AUTOOBS_NEED_EXCHANGE: '180',
  _26_FEEDBACK_SOLICITATION_EMAIL: 'Feedback spontané',
  _27_SURVEY_LAUNCH: 'Enquête',
  _28_SURVEY_FOLLOW_UP_START: 'Enquête',
  _29_SURVEY_FOLLOW_UP_END: 'Enquête',
  _30_SURVEY_FOLLOW_UP_LAST_DAY: 'Enquête',
  _31_SURVEY_FOLLOW_UP_OVERTIME: 'Enquête',
  _32_PDI_LAUNCH_EMPLOYEE: 'PDI',
  _33_PDI_LAUNCH_MANAGER: 'PDI',
  _34_PDI_READY_PICK_FORMATION: 'PDI',
  _35_PDI_RESULT: 'PDI',
  _36_MIF_PARTICIPANT_ACQUISITION_BEFORE: 'Impact formation',
  _37_MIF_RELANCE_PARTICIPANT_ACQUISITION_BEFORE: 'Impact formation',
  _38_MIF_MANAGER_FORMATION_INFOS: 'Impact formation',
  _39_MIF_TRAINER_FORMATION_INFOS: 'Impact formation',
  _40_MIF_PARTICIPANT_SATISFACTION: 'Impact formation',
  _41_MIF_PARTICIPANT_ACQUISITION_AFTER: 'Impact formation',
  _42_MIF_RELANCE_PARTICIPANT_ACQUISITION_AFTER: 'Impact formation',
  _43_MIF_MANAGER_AFTER_FORMATION: 'Impact formation',
  _44_MIF_PARTICIPANT_EVA_DEFINED: 'Impact formation',
  _45_MIF_MANAGER_EVA_DEFINED: 'Impact formation',
  _47_MIF_RELANCE_MANAGER_EVA: 'Impact formation',
  _48_MIF_LAST_RELANCE_MANAGER_EVA: 'Impact formation',
  _50_EMA_PLANNED: 'EMA',
  _51_SURVEY_PARTICIPATION: 'Enquête',
  _52_RAPPORT_EMA_EMAIL: 'EMA',
  _53_MIF_EVENT_ACQUISITION_BEFORE: 'Impact formation',
  _54_MIF_EVENT_ACQUISITION_AFTER: 'Impact formation',
  _55_MIF_EVENT_SATISFACTION: 'Impact formation',
  _56_MIF_EVENT_SATISFACTION_RELANCE: 'Impact formation',
  _57_MIF_EVENT_ACQUISITION_BEFORE_RELANCE: 'Impact formation',
  _58_MIF_EVENT_ACQUISITION_AFTER_RELANCE: 'Impact formation',
};
