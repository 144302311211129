// ENTITY fr.hiji.metier.campaign.dto.back._360.Campaign360ObservableGestureBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { Campaign360ObservableGesture } from './Campaign360ObservableGesture';

export class Campaign360ObservableGestureBODTO {

  observableGesture: Campaign360ObservableGesture | null = null;
  observableGestureName: MultilingualString | null = new MultilingualString();
  idsTags: number[] | null = [];
  observableGestureDel: boolean | null = false;

  constructor(init?:Partial<Campaign360ObservableGestureBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back._360.Campaign360ObservableGestureBODTO'] = Campaign360ObservableGestureBODTO;

reverseMapping[Campaign360ObservableGestureBODTO.name] = 'fr.hiji.metier.campaign.dto.back._360.Campaign360ObservableGestureBODTO';

fields['fr.hiji.metier.campaign.dto.back._360.Campaign360ObservableGestureBODTO']  = {
    observableGesture: 'fr.hiji.metier.campaign.entities._360.Campaign360ObservableGesture',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    idsTags: 'java.util.List<java.lang.Integer>',
    observableGestureDel: 'java.lang.Boolean'
};
