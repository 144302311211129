import { Component, input } from '@angular/core';

@Component({
  selector: 'hiji-created-updated-by',
  templateUrl: './created-updated-by.component.html',
  styleUrl: './created-updated-by.component.scss',
})
export class CreatedUpdatedByComponent {
  userCreator = input<string>('');
  creationDate = input<Date>(null);
  userUpdater = input<string>('');
  updateDate = input<Date>(null);
  constructor() {}
}
