// fr.hiji.metier.ema.services.back.EMAStatsBOServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:01 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMACustomStatsBODTO } from '../entities/EMACustomStatsBODTO';
import { EMAStatsFilterBODTO } from '../entities/EMAStatsFilterBODTO';
import { EMAParticipationStatsDTO } from '../entities/EMAParticipationStatsDTO';
import { EMARepositoryStatsBODTO } from '../entities/EMARepositoryStatsBODTO';
import { EMAPerceptionStatsBODTO } from '../entities/EMAPerceptionStatsBODTO';

@Injectable({
  providedIn: 'root',
})
export class EMAStatsBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getObservableGestureStatsCsv(filters: EMAStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getObservableGestureStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getParticipationStats(filters: EMAStatsFilterBODTO): RpcRequestBuilder<EMAParticipationStatsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getParticipationStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.back.stats.EMAParticipationStatsDTO');
  }

  getRepositoryStats(filters: EMAStatsFilterBODTO): RpcRequestBuilder<EMARepositoryStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getRepositoryStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.back.stats.EMARepositoryStatsBODTO');
  }

  getCustomStats(filters: EMAStatsFilterBODTO): RpcRequestBuilder<EMACustomStatsBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getCustomStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.back.stats.EMACustomStatsBODTO');
  }

  getPerceptionStats(filters: EMAStatsFilterBODTO): RpcRequestBuilder<EMAPerceptionStatsBODTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getPerceptionStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getManagersStatsCsv(filters: EMAStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getManagersStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getEtablissementsStatsCsv(filters: EMAStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getEtablissementsStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  getSkillStatsCsv(filters: EMAStatsFilterBODTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAStatsBOService.getSkillStatsCsv';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filters, 'fr.hiji.metier.ema.dto.back.stats.EMAStatsFilterBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
