// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionDate generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionDatePeriod } from './FormationSessionDatePeriod';

export class FormationSessionDate {

  idFormationSession: number | null = 0;
  date: Date | null = null;
  period: FormationSessionDatePeriod | null = null;
  attendanceCode: string | null = '';

  constructor(init?:Partial<FormationSessionDate>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionDate'] = FormationSessionDate;

reverseMapping[FormationSessionDate.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionDate';

fields['fr.hiji.metier.mif.entities.session.FormationSessionDate']  = {
    idFormationSession: 'java.lang.Integer',
    date: 'java.time.LocalDate',
    period: 'fr.hiji.metier.mif.entities.session.FormationSessionDatePeriod',
    attendanceCode: 'java.lang.String'
};
