<div class="row">
  <div class="col-10 mt-2">
    <h3 class="m-3">{{ observableGesture.observableGestureName | ms }}</h3>
    <div class="d-flex flex-column">
      <hiji-tag-display [idTag]="tag.idTag" *ngFor="let tag of getAssignedTags()"></hiji-tag-display>
    </div>
  </div>
  <div class="col-2 mt-2 text-end" *ngIf="showClose">
    <i class="fa-light fa-times-circle fa-3x pointer" style="color: #feba01" (click)="close.emit()"></i>
  </div>
</div>
<div class="row mt-4">
  <div class="chart-wrapper">
    <hiji-download-graph [imgName]="observableGesture.observableGestureName | ms">
      <canvas
        id="ogChart{{ observableGesture.idObservableGesture }}"
        height="100px"
        width="100%"
        baseChart
        [data]="chartData"
        [options]="chartOptions"
        [legend]="true"
        [type]="chartType"
        [plugins]="pieChartPlugins"
      ></canvas>
    </hiji-download-graph>
  </div>
</div>
