// ENTITY fr.hiji.metier.reporting.entities._180.Report180ConfigCampaign generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Report180ConfigCampaign {

  idReport180Config: number | null = 0;
  idCampaign: number | null = 0;

  constructor(init?:Partial<Report180ConfigCampaign>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities._180.Report180ConfigCampaign'] = Report180ConfigCampaign;

reverseMapping[Report180ConfigCampaign.name] = 'fr.hiji.metier.reporting.entities._180.Report180ConfigCampaign';

fields['fr.hiji.metier.reporting.entities._180.Report180ConfigCampaign']  = {
    idReport180Config: 'java.lang.Integer',
    idCampaign: 'java.lang.Integer'
};
