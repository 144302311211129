// ENTITY fr.hiji.metier.reporting.dto.pdf180.PodiumOrderedGesteObservable generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class PodiumOrderedGesteObservable {

  percent: number | null = 0;
  percentPrevious: number | null = 0;
  name: string | null = '';
  order: number | null = 0;
  nb: number | null = 0;
  nbObs: number | null = 0;
  nbObsPrevious: number | null = 0;

  constructor(init?:Partial<PodiumOrderedGesteObservable>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.pdf180.PodiumOrderedGesteObservable'] = PodiumOrderedGesteObservable;

reverseMapping[PodiumOrderedGesteObservable.name] = 'fr.hiji.metier.reporting.dto.pdf180.PodiumOrderedGesteObservable';

fields['fr.hiji.metier.reporting.dto.pdf180.PodiumOrderedGesteObservable']  = {
    percent: 'java.lang.Integer',
    percentPrevious: 'java.lang.Integer',
    name: 'java.lang.String',
    order: 'java.lang.Integer',
    nb: 'java.lang.Integer',
    nbObs: 'java.lang.Integer',
    nbObsPrevious: 'java.lang.Integer'
};
