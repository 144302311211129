// fr.hiji.metier.mif.services.front.FormationSessionExpectationsServiceI generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:00 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationSessionParticipantExpectationsDTO } from '../entities/FormationSessionParticipantExpectationsDTO';
import { FormationSessionExpectationsResultDTO } from '../entities/FormationSessionExpectationsResultDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionExpectationsService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getExpectations(idParticipant: number, idFormationSession: number): RpcRequestBuilder<FormationSessionParticipantExpectationsDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionExpectationsService.getExpectations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.FormationSessionParticipantExpectationsDTO');
  }

  getExpectationsResult(idFormationSession: number): RpcRequestBuilder<FormationSessionExpectationsResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionExpectationsService.getExpectationsResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.FormationSessionExpectationsResultDTO');
  }

  saveSessionExpectations(expectations: FormationSessionParticipantExpectationsDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionExpectationsService.saveSessionExpectations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(expectations, 'fr.hiji.metier.mif.dto.front.FormationSessionParticipantExpectationsDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
