// ENTITY fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplayTarget generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:08 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityRoleplayTarget {

  idModality: number | null = 0;
  idTag: number | null = 0;
  value: number | null = 0;

  constructor(init?:Partial<ModalityRoleplayTarget>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplayTarget'] = ModalityRoleplayTarget;

reverseMapping[ModalityRoleplayTarget.name] = 'fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplayTarget';

fields['fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplayTarget']  = {
    idModality: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    value: 'java.lang.Integer'
};
