import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hiji-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
})
export class DatetimePickerComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  private _disabled: boolean;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }
  onChange: any = () => {};
  onTouch: any = () => {};
  value: string;

  control: NgControl;

  constructor(private injector: Injector) {}

  // The form control is only set after initialization
  ngAfterContentInit(): void {
    this.control = this.injector.get(NgControl, null);
  }

  ngOnInit(): void {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouch = fn;
  }
  writeValue(value: Date) {
    if (value === null || value === undefined) this.value = null;
    else {
      const tz = value.getTimezoneOffset() * 60000;
      const date = new Date(value.getTime() - tz);
      this.value = date.toISOString().slice(0, 16);
    }
  }

  change() {
    if (this.value === null || this.value === undefined || isNaN(Date.parse(this.value))) {
      //console.log('SENDING NULL');
      this.onChange(null);
      this.onTouch(null);
    } else {
      let date = new Date(Date.parse(this.value));
      //const tz = date.getTimezoneOffset() * 60000;
      //date = new Date(date.getTime() - tz);
      //console.log(this.value, date);
      //console.log('SENDING ', date);
      this.onChange(date);
      this.onTouch(date);
    }
  }
}
