// ENTITY fr.hiji.metier.reseau.dto.PopulationFilterBODTO generated by ic2 0.0.1-SNAPSHOT at Wed Sep 11 18:48:09 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Organisation } from './Organisation';
import { PopulationFilter } from './PopulationFilter';

export class PopulationFilterBODTO {

  filter: PopulationFilter | null = null;
  organisation: Organisation | null = null;
  fonctionName: string | null = '';
  fonctionDel: boolean | null = false;
  userName: string | null = '';
  userDel: boolean | null = false;

  constructor(init?:Partial<PopulationFilterBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.dto.PopulationFilterBODTO'] = PopulationFilterBODTO;

reverseMapping[PopulationFilterBODTO.name] = 'fr.hiji.metier.reseau.dto.PopulationFilterBODTO';

fields['fr.hiji.metier.reseau.dto.PopulationFilterBODTO']  = {
    filter: 'fr.hiji.metier.reseau.entities.PopulationFilter',
    organisation: 'fr.hiji.metier.reseau.entities.Organisation',
    fonctionName: 'java.lang.String',
    fonctionDel: 'java.lang.Boolean',
    userName: 'java.lang.String',
    userDel: 'java.lang.Boolean'
};
